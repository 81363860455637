import React from "react";
// Customizable Area Start
import { Box, Button, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, styled } from "@mui/material";
import NavigationSidebar from "../../../components/src/NavigationSidebar.web";
import { dashboardOrderLogo, activeOrderDashboard, accountsOrder, activeOrderAccount, ordersIcon, activeOrderIcon, vendorOrder, activeOrderVendor, remindersOrder, activeOrderReminder, propertyOrder, activePropertyOrder, financialOrder, activeOrderFinancial, reportOrder, activeOrderReport, keyOrderChain, activeKeyOrder, settingsOrder, activeOrderSetting, keasIcon, hexagon, halfHexagon } from './assets';
import { AuditLogs, Documents} from "./OpenWorkOrderController.web";
import { ArrowDropDown, ArrowDropUp, Collections } from '@mui/icons-material';
// Customizable Area End
import OpenWorkOrderController, {
  Props,
  configJSON,
} from "./OpenWorkOrderController.web";

export default class OpenWorkOrder extends OpenWorkOrderController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  renderSortableCell = (property: string, label: string) => {
    return (
      <CustomCell key={property}>
        <Box display="flex" alignItems="center" justifyContent={"space-evenly"}>
          {label}
          <Box display="flex" flexDirection="column" marginLeft={"10px"}>
            <CustomButtonUp
              data-test-id="sortUpId"
              onClick={() => this.handleSortRequest(property, "asc")}
            >
              <ArrowDropUp
              />
            </CustomButtonUp>
            <CustomButtonDown
              data-test-id="downId"
              onClick={() => this.handleSortRequest(property, "desc")}
            >
              <ArrowDropDown
              />
            </CustomButtonDown>
          </Box>
        </Box>
      </CustomCell>
    );
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
      return (
        <WorkOrderStyles>
          <NavAreas>
          <NavigationSidebar
            data-test-id={"navBarId"}
            activeItem={this.state.activeOrder}
            sidebarItems={[
              { labelKey: configJSON.dashboardOrderlabel, label: configJSON.dashboardOrder, icon: dashboardOrderLogo,pathName: configJSON.dashboardOrderlabel, activeIcon: activeOrderDashboard,  },
              { pathName: configJSON.myAccountOrderabel, label: configJSON.myAccountOrder, labelKey: configJSON.settingOrderlabel, icon: accountsOrder, activeIcon: activeOrderAccount },
              { labelKey: configJSON.worksOrderlabel, icon: ordersIcon, activeIcon: activeOrderIcon, pathName: configJSON.worksOrderlabel,  label: configJSON.workOrder },
              { labelKey: configJSON.prevendorOrderlabel, label: configJSON.preferredOrder, icon: vendorOrder, activeIcon: activeOrderVendor, pathName: configJSON.prevendorOrderlabel },
              { labelKey: configJSON.reminOrderlabel,label: configJSON.remindersOrder, icon: remindersOrder, activeIcon: activeOrderReminder, pathName: configJSON.reminOrderlabel },
              { labelKey: configJSON.mypropOrderlabel, label: configJSON.mypropOrder,icon: propertyOrder, activeIcon: activePropertyOrder, pathName: configJSON.mypropOrderlabel },
              { labelKey: configJSON.financOrderlabel, label: configJSON.financiaOrder, icon: financialOrder, activeIcon: activeOrderFinancial, pathName: configJSON.financOrderlabel },
              { labelKey: configJSON.reportOrderlabel,label: configJSON.reportOrder, icon: reportOrder, activeIcon: activeOrderReport, pathName: configJSON.reportOrderlabel },
              { labelKey: configJSON.chatOrderlabel,label: configJSON.chatOrder, icon: keyOrderChain, activeIcon: activeKeyOrder, pathName: configJSON.chatOrderlabel },
              { labelKey: configJSON.workOrder, icon: settingsOrder,label: configJSON.settingOrder, activeIcon: activeOrderSetting, pathName: configJSON.settingOrderlabel }
            ]}
            onClickSidebar={this.handleSideBarNav}
            keasylogo={keasIcon}
            openDrawer={this.state.openOrder}
            onClickDrawer={this.handleOpenDrawer}
          />
          </NavAreas>
          <WorkAreas display={"flex"} justifyContent={"space-between"} flexDirection={"column"} width={"100%"}>
            <MainWorkContainer>
              <WorkStyles>
                <WorkOrders data-test-id="seeOpentId">
                  {configJSON.workOrders} #{this.state.showOpenWorks.data.id}
                </WorkOrders>
                <BorderContainer>
                  <Grid container spacing={2} mt={2}>
                    <GridItem item xl={6} lg={6} md={6} sm={12}>
                      <HeadItems>
                        <HeadName>{this.state.showOpenWorks.data.attributes.property.property_name}|{this.state.showOpenWorks.data.attributes.unit.name}</HeadName>
                        <MainHeadings>{this.state.showOpenWorks.data.attributes.property.address}</MainHeadings>
                      </HeadItems>
                    </GridItem>
                    <GridItem item xl={6} lg={6} md={6} sm={12}>
                    </GridItem>
                  </Grid>
                  <Box>
                    <Box className="InputWrapper">
                      <Box className="InputWrapperLeft">
                        {configJSON.categoryName}
                      </Box>
                      <Box className="InputWrapperRight">
                        <input
                          readOnly
                          value={this.state.showOpenWorks.data.attributes.sub_category.sub_category ? this.state.showOpenWorks.data.attributes.sub_category.sub_category : configJSON.naTextValue}
                          className='input'
                          data-test-id="catInputId"
                        />
                      </Box>
                    </Box>
                    <Box className="InputWrapper">
                      <Box className="InputWrapperLeft">
                        {configJSON.workNeedName}
                      </Box>
                      <Box className="InputWrapperRight">
                        <input
                          value={this.capitalizeFirstLetter(this.state.showOpenWorks.data.attributes.work_needed)}
                          readOnly
                          className='input'
                          data-test-id="workNeededTest"
                        />
                      </Box>
                    </Box>
                    <Box className="InputWrapper">
                      <Box className="InputWrapperLeft">
                        {configJSON.SelectedVendor}
                      </Box>
                      <Box className="InputWrapperRight" display={"flex"} flexDirection={"column"} gap={1}>
                        <CustomTypography>{this.state.showOpenWorks.data.attributes.vendor_account.name}</CustomTypography>
                        <CustomTypography>{this.state.showOpenWorks.data.attributes.vendor_account.phone_number}</CustomTypography>
                        <CustomTypography>{this.state.showOpenWorks.data.attributes.vendor_account.email_address}</CustomTypography>
                      </Box>
                    </Box>
                    <Box className="InputWrapper">
                      <Box className="InputWrapperLeft">
                        {configJSON.status}
                      </Box>
                      <Box className="InputWrapperRight">
                        <OrderOpenStatus data-test-id="statusId" sx={this.priorityOpenStyleHandler(this.state.showOpenWorks.data.attributes.status)}>
                          {this.state.showOpenWorks.data.attributes.status}
                        </OrderOpenStatus>
                      </Box>
                    </Box>
                    <Box className="InputWrapper">
                      <Box className="InputWrapperLeft">
                        {configJSON.descriptionName}
                      </Box>
                      <Box className="InputWrapperRight">
                        <textarea
                          readOnly
                          value={this.state.showOpenWorks.data.attributes.description}
                          className='textArea'
                          rows={6}
                          data-test-id="descriptionId"
                        />
                      </Box>
                    </Box>
                    <Box className="InputWrapper">
                      <Box className="InputWrapperLeft">
                        {configJSON.goingOnName}
                      </Box>
                      <Box className="InputWrapperRight">
                        <CustomTableContainer className={this.handleScrollContainer(this.state.showOpenWorks.data.attributes.work_order_audits)}>
                          <Table>
                            <TableHead>
                              <TableRow>
                                {this.renderSortableCell("date", configJSON.dateName)}
                                {this.renderSortableCell("updated_by", configJSON.updatedName)}
                                <DetailsCell>{configJSON.detailsName}</DetailsCell>
                                {this.renderSortableCell("lowCost", configJSON.costName)}
                                {this.renderSortableCell("status", configJSON.statusChangeName)}
                                <TableCell />
                              </TableRow>
                            </TableHead>
                            <CustomContainer>
                              {this.sortAuditData(this.state.showOpenWorks.data.attributes.work_order_audits).map((audit: AuditLogs, auditIndex: number) => {
                                return (
                                  <React.Fragment key={auditIndex}>
                                    <TableRow className={this.handleAuditLog(this.state.showOpenWorks.data.attributes.work_order_audits)}>
                                      <StatusCell data-test-id="sortId">{audit.date}</StatusCell>
                                      <StatusCell>{audit.updated_by}</StatusCell>
                                      <StatusCells>{audit.description}</StatusCells>
                                      <StatusCell>{configJSON.lowCost}</StatusCell>
                                      <StatusCell>{audit.status}</StatusCell>
                                    </TableRow>
                                  </React.Fragment>
                                );
                              })}
                            </CustomContainer>
                            <TableRow className={this.handleEmptyAuditLogs(this.state.showOpenWorks.data.attributes.work_order_audits)}>
                              <TableCell colSpan={5} align="center">
                                {configJSON.auditMissing}
                              </TableCell>
                            </TableRow>
                          </Table>
                        </CustomTableContainer>
                      </Box>
                    </Box>
                    <Box className="InputWrapper">
                      <Box className="InputWrapperLeft">
                        {configJSON.fileAdded}
                      </Box>
                      <Box className="InputWrapperRight fileUpload">
                        {this.state.showOpenWorks.data.attributes.documents.map((documents: Documents, docIndex: number) => {
                          return (
                            <Box key={docIndex} display={"flex"} justifyContent={"space-between"} width={"80%"}>
                              <Box display={"flex"} alignItems={"center"}>
                                <Collections className="file_icon" />
                                <Typography className="file_name">{documents.file_name}</Typography>
                                <Typography className="file_size">
                                </Typography>
                              </Box>
                              <Button
                                data-test-id="previewId"
                                variant="text"
                                className="preview_button"
                                onClick={() => this.handlePreview(documents)}
                              >
                                {configJSON.preview}
                              </Button>
                            </Box>
                          )
                        })}
                      </Box>
                    </Box>
                    <Box className="InputWrapper">
                      <Box className="InputWrapperLeft">
                      </Box>
                      <Box className="submitBtnWrapper">
                        <Button data-test-id="btnSubmitId" className="submitBtn" onClick={this.goBackWorkOrder}>{configJSON.backButton}</Button>
                      </Box>
                    </Box>
                  </Box>
                </BorderContainer>
              </WorkStyles>
            </MainWorkContainer>
            <Box className="bgImgWrapper" data-test-id="bgId">
              <Box className="backgroundImages">
                <img src={hexagon} alt="hexagons" className="hexImg" />
              </Box>
              <Box className="backgroundImages">
                <img src={hexagon} alt="hexagons" className="hexImg" />
              </Box>
              <Box className="backgroundImages">
                <img src={hexagon} alt="hexagons" className="hexImg" />
              </Box>
              <Box className="backgroundImages">
                <img src={halfHexagon} alt="half" className="hexImg" />
              </Box>
            </Box>
          </WorkAreas>
        </WorkOrderStyles>
      );
    // Customizable Area End
  }
}

// Customizable Area Start
const MainWorkContainer = styled(Box)({
  width: "100%",
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  "@media(max-width: 992px)": {
    padding: "0px 0px 0px 0px"

  }
});

const WorkOrderStyles = styled(Box)({
  display: "flex",
  width: "100%",
  justifyContent: "space-between",
  "@media(max-width: 992px)": {
    flexDirection: "column"
  },
  "&.webStyle": {
    backgroundColor: "red"
  },
  "& .bgImgWrapper": {
    display: "flex",
    justifyContent: "flex-end",
    width: "97%",
    marginTop: "2rem",
    "@media (max-width:1220px)": {
      width: "88%"
    },
    "@media (max-width:992px)": {
      display: "none",
    }
  },
  "& .backgroundImages": {
    width: "250px",
    "@media (max-width:1220px)": {
      width: "180px"
    },
  },
  "& .hexImg": {
    objectFit: "cover"
  }
});

const WorkStyles = styled(Box)({
  marginTop: "42px",
  width: "90%",
  "& .search-main-content": {
    gap: "1rem",
    display: "flex",
    "@media(max-width:600px)": {
      width: "100%",
      height: "100%",
      flexDirection: "row",
      display: "flex",
      position: "relative",
      "@media(max-width: 420px)": {
        gap: "5px"
      }
    },
  },
  "& .search-contents": {
    borderRadius: "50px",
    display: "flex",
    height: "44px",
    paddingLeft: "15px",
    alignItems: "center",
    border: "1px solid #CBD5E1",
    gap: "13px",
  },
  "& .search-icons": {
    width: "24px",
    height: "24px",
  },
  "& .search-text": {
    fontSize: "16px",
    fontWeight: 400,
    color: "#A3978F",
    "@media(max-width: 420px)": {
      fontSize: "12px"
    }
  },
  "& .search-inputs": {
    width: "100%"
  },
  "& .properties-dropdown": {
    width: "142px",
    height: "44px",
    backgroundColor: "rgb(253,242,208)",
    display: "flex",
    borderRadius: "8px",
    alignItems: "center",
    textTransform: "none",
    padding: "2px 10px 2px 10px",
    justifyContent: "space-around",
    cursor: "pointer",
    color: "#000000",
    "&:hover": {
      color: "#000000",
      backgroundColor: "rgb(253,242,208)",
    },
    "@media(max-width: 420px)": {
      fontSize: "12px",
      width: "105px",
      height: "30px"
    }
  },
  "& .view-completed": {
    width: "180px",
    height: "44px",
    display: "flex",
    borderRadius: "8px",
    alignItems: "center",
    padding: "2px 10px 2px 10px",
    justifyContent: "space-around",
    backgroundColor: "rgb(253,242,208)",
    cursor: "pointer",
    "@media(max-width: 460px)": {
      fontSize: "12px",
      height: "35px",
      width: "100%"
    },
    "@media(max-width: 347px)": {
      padding: "2px 5px 2px 5px"
    }
  },
  "& .icon": {
    mixBlendMode: "multiply"
  },
  "& .image-icon": {
    height: "16px",
    width: "16px",
  },
  "& .text-data": {
    fontWeight: 700,
    fontSize: "16px",
    "@media(max-width: 420px)": {
      fontSize: "12px"
    }
  },
  "& .new-data": {
    color: "#CC9200",
    fontWeight: 700,
    fontSize: "18px",
    "@media(max-width: 420px)": {
      fontSize: "12px"
    }
  },
  "& .new-property": {
    display: "flex",
    gap: "10px",
    alignItems: "center",
    justifyContent: "space-around",
    borderRadius: "8px",
    paddingLeft: "5px",
    paddingRight: "5px",
    cursor: "pointer"
  },
  "& .filter-content": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingRight: "20px"
  },
  "& .filter": {
    display: "flex",
    gap: "15px",
    alignItems: "center",
  },
  "& .filter-data": {
    fontWeight: 700,
    fontSize: "12px",
    color: "#000000"
  },
  "& .clear-data": {
    fontSize: "12px",
    color: "#CC9200",
    fontWeight: 700,
    cursor: "pointer"
  }
});

const WorkOrders = styled(Typography)({
  fontSize: "24px",
  textTransform: "capitalize",
  fontWeight: 700,
  color: "#000000",
  marginBottom: "20px"
});

const GridItem = styled(Grid)({
  width: "100%"
});

const BorderContainer = styled(Box)({
  border: "1px solid #FFD466",
  marginTop: "1rem",
  display: "flex",
  flexDirection: "column",
  borderRadius: "8px 8px 32px 8px",
  padding: "1rem",
  "& .scrollable": {
    height: "300px",
    overflowY: "auto"
  },
  "& .isUnitsDisabled": {
    display: "none"
  },
  "& .openOrderForm": {
    width: "90%",
    margin: "2rem",
    borderRadius: "8px",
    border: "1px solid #FFE299",
    padding: "2rem",
    "@media (max-width:1200px)": {
      padding: "0rem",
      width: "95%",
    },
    "@media (max-width:1000px)": {
      margin: "0.5rem",
    },
    "@media (max-width:600px)": {
      border: "none",
      width: "90%",
    }
  },
  "& .InputWrapper": {
    padding: "2rem 0rem 2rem 2rem",
    display: "flex",
    "@media (max-width:600px)": {
      display: "block",
      padding: "1rem 0rem 1rem 0rem",
    }
  },
  "& .InputWrapperLeft": {
    width: "20%",
    lineHeight: "17px",
    fontWeight: 700,
    fontSize: "18px",
    color: "#BAB1AB",
    "@media (max-width:600px)": {
      width: "100%",
    }
  },
  "& .InputWrapperRight": {
    display: "flex",
    flexDirection: "column",
    marginRight: "25px",
    width: "80%",
    '&::first-letter': {
      textTransform: 'capitalize',
    },
    "@media (max-width:600px)": {
      width: "100%",
      display: "block",
      marginTop: "1rem",
    }
  },
  "& .submitBtn": {
    width: "175px",
    height: "44px",
    cursor: "pointer",
    borderRadius: "8px",
    color: "#000000",
    marginRight: "25px",
    fontWeight: 700,
    backgroundColor: "#FFC123",
    fontSize: '16px',
    lineHeight: '24px',
    fontFamily: "Outfit, sans-serif",
    "&:hover": {
      backgroundColor: "#FFD700", 
    },
    "@media (max-width:600px)": {
      marginRight: "0px",
    }
  },
  "& .submitBtnWrapper": {
    width: "670px",
    justifyContent: "flex-end",
    display: "flex",
    cursor: "pointer",
    "@media (max-width:600px)": {
      width: "100%",
      justifyContent: "flex-start",
    }
  },
  "& .textArea": {
    width: "670px",
    borderRadius: "8px",
    padding: "10px",
    border: "1px solid #BAB1AB",
    marginRight: "25px",
    "@media (max-width:1000px)": {
      width: "100%",
    }
  },
  "& .input": {
    width: "670px",
    height: "44px",
    border: "1px solid #BAB1AB",
    borderRadius: "8px",
    paddingLeft: "10px",
    "@media (max-width:1200px)": {
      width: "100%",
    }
  },
  "& .file_icon": {
    color: "#CC9200",
  },
  "& .file_name": {
    fontFamily: "Outfit, sans-serif",
    fontSize: "16px",
    marginLeft: "11px",
    overflow: "hidden",
    fontWeight: 400,
    lineHeight: "18px",
    color: "#000000",
    maxWidth: "10rem",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  "& .file_size": {
    fontFamily: "Outfit, sans-serif",
    fontSize: "16px",
    lineHeight: "18px",
    color: "#A3978F",
    marginLeft: "19px",
    fontWeight: 400
  },
  "& .preview_button": {
    lineHeight: "22px",
    fontFamily: "Outfit, sans-serif",
    fontSize: "14px",
    fontWeight: 700,
    color: "#CC9200",
    textTransform: "capitalize",
  },
});

const HeadName = styled(Typography)({
  fontSize: "16px",
  color: "#000000",
  fontWeight: 700,
  '&::first-letter': {
    textTransform: 'capitalize',
  },
  "@media(max-width: 405px)": {
    fontSize: "12px"
  }
});

const MainHeadings = styled(Typography)({
  color: "#000000",
  fontSize: "16px",
  fontWeight: 400,
  '&::first-letter': {
    textTransform: 'capitalize',
  },
  "@media(max-width: 405px)": {
    fontSize: "12px"
  }
});

const HeadItems = styled(Box)({
  gap: "10px",
  display: "flex",
  padding: "0rem 0rem 1rem 2rem"
});

const OrderOpenStatus = styled(Box)({
  borderRadius: "50px",
  backgroundColor: "#D1FAE5",
  color: "#059669",
  fontSize: "9px",
  alignItems: "center",
  padding: "10px 20px",
  width: "fit-content",
  borderColor: "#D1FAE5",
  fontWeight: 700
});

const CustomContainer = styled(TableBody)({
  backgroundColor: "#FFFFFF"
});

const DetailsCell = styled(TableCell)({
  color: "#A3978F",
  whiteSpace: "nowrap",
  fontSize: "12px",
  textAlign: "center",
  fontWeight: 700
});

const CustomCell = styled(TableCell)({
  color: "#A3978F",
  textAlign: "center",
  fontSize: "12px",
  fontWeight: 700,
  whiteSpace: "nowrap",
  padding: "0px 10px 0px 10px !important"
});

const CustomButtonUp = styled(Typography)({
  marginBottom: "-14px",
  cursor: "pointer"
});

const CustomButtonDown = styled(Typography)({
  marginTop: "0px",
  cursor: "pointer"
});

const StatusCell = styled(TableCell)({
  fontSize: "12px",
  fontWeight: 400,
  whiteSpace: "nowrap",
  color: "#000000",
  textAlign: "center"
});

const CustomTableContainer = styled(TableContainer)({
  width: "80%"
});

const StatusCells = styled(TableCell)({
  fontSize: "12px",
  fontWeight: 400,
  color: "#000000",
  textAlign: "center"
});

const CustomTypography =styled(Typography)({
  color: "#332500",
  fontSize: "16px",
  fontWeight: 400
});

const NavAreas = styled(Box)({
  position: "fixed",
  top: 0,
  border: "1px solid rgb(208,203,199)",
  left: 0,
  overflowY: "auto",
  zIndex: 1000, 
  height: "100%",
  "@media(max-width: 992px)": {
    position: "unset",
    height: "auto",
    overflowY: "hidden",
    border: "none",
  }
});

const WorkAreas = styled(Box)({
  marginLeft: "90px", 
  flexGrow: 1,
  height: "100vh",
  overflowY: "auto",
  "@media(max-width: 992px)": {
    marginLeft: "0px", 
    overflowY: "unset",
    height: "auto",
  }
});
// Customizable Area End
