import React from "react";

// Customizable Area Start
import {
  Box,
  Grid,
  InputBase,
  Typography,
  styled,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  DialogContent,
  IconButton,
  TableRow,
  ListItemText,
  Menu,
  MenuItem,
  Dialog,
  TextField,
  DialogActions,
  DialogTitle,
  FormControl,
  Select,
  Modal,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio
} from '@mui/material';
import NavigationSidebar from "../../../components/src/NavigationSidebar.web";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import PopupMessage from "../../../components/src/PopupMessage.web";
import { CustomRadioButton } from "../../../components/src/CustomRadioButton.web";
import { ArrowDropDown, ArrowDropUp, Close, KeyboardArrowUp, KeyboardArrowDown } from '@mui/icons-material';
import { PreferredVendor, VendorWorks, FormW, SubCategory, CatType, AttachmentsForm, VendorList } from "./TaskListController";
import { pencilIcon, closeIcon, activeMainDashboard, keasyIcon, dashboardLandLogo, vendorMain, SearchBarIcon, filterImageIcon, viewHexagonIcon, vendorInfoImageIcon, accountsMain, activeMainAccount, ordersMain, activeMainVendor, remindersMain, activeKeyMain, activeMainFinancial, activeMainOrder, activeMainReminder, activeMainReport, activeMainSetting, activePropertyMain, financialMain, keyMainChain, propertyMain, reportMain, settingsMain, AddPlusIcon, DefaultImgPlcIcon, bottomImageIcon  } from "./assets";
import Loader from "../../../components/src/Loader.web";
// Customizable Area End

import TaskListController, { configJSON, Props } from "./TaskListController";

// Customizable Area Start
// Customizable Area End

export default class TaskList extends TaskListController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderSortVendorTableCell = (property: string, label: string) => {
    return (
      <CustomTableCell key={property}>
        <Box display="flex" alignItems="center" justifyContent={"center"}>
          {label}
          <Box display="flex" flexDirection="column" marginLeft={"10px"}>
            <CustomIconButtonUp
              data-test-id="sortingId"
              onClick={() => this.handleVendorSort(property, "asc")}
            >
              <ArrowDropUp
              />
            </CustomIconButtonUp>
            <CustomIconButtonDown
             data-test-id="sortingDown"
             onClick={() => this.handleVendorSort(property, "desc")}
            >
              <ArrowDropDown
              />
            </CustomIconButtonDown>
          </Box>
        </Box>
      </CustomTableCell>
    );
  };

  renderField = () => {
    return(
      <Box>
      <Grid container spacing={2}>
        <Grid item xs={5} sm={3}>
          <FormControl fullWidth>
            <label style={webStyle.textstylechange12}>{configJSON.areaCodes}</label>
            <Select
              required
              data-test-id="countryCodeId"
              name="countryCode"
              variant="outlined"
              size="medium"
              value={this.state.areasCode}
              onClose={this.handleCountClose}
              onChange={this.handleCountryChange}
              onOpen={this.handleCountryCodeDropdown}
              sx={{
                border: "1px solid #e0e0e0",
                borderRadius: "8px",
              }}
            >
              <MenuItem value={this.state.areasCode}>{configJSON.countries}</MenuItem>
              {this.state.countriesCodes?.map((country) => (
                <MenuItem key={country.name} value={country.country_code}>
                  <Typography sx={{ display: this.state.isCountryDropdown ? "block" : "none" }}>
                    {country.emoji_flag} {country.name} (+{country.country_code})
                  </Typography>
                  <Typography sx={{ display: this.state.isCountryDropdown ? "none" : "block" }}>+{country.country_code}</Typography>
                </MenuItem>
              ))}
          </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={9}>
          <label style={webStyle.textstylechange12}>{configJSON.phoneNumbers}</label>
          <TextField
            required
            data-test-id="phoneId"
            name="phoneNumber"
            fullWidth
            variant="outlined"
            sx={webStyle.marginManage}
            size="medium"
            error={this.state.errors.phones}
            helperText={this.getHelpersText("phoneNumber")}
            value={this.state.phoneNumber}
            onChange={this.handleChange}
          />
        </Grid>
      </Grid>
      <Box style={webStyle.marginBorderstyle}>
        <label style={webStyle.labeltextsyle}>{configJSON.email}</label>
        <TextField
          data-test-id="emailId"
          variant="outlined"
          fullWidth
          name="email"
          value={this.state.email}
          onChange={this.handleChange}
          sx={webStyle.marginManage}
          error={this.state.errors.emails}
          helperText={this.getHelpersText("email")}
        />
         </Box>

      <Box style={webStyle.marginBorderstyle}>
        <label style={webStyle.labeltextsyle}>{configJSON.address}</label>
        <TextField
          data-test-id="addressId"
          variant="outlined"
          fullWidth
          name="address"
          value={this.state.address}
          error={this.state.errors.address}
          helperText={this.getHelpersText("address")}
          onChange={this.handleChange}
          sx={webStyle.marginManage}
        />
      </Box>
        <Box style={webStyle.marginBorderstyle}>
          <FormControl sx={{ marginBottom: '16px' }}>
            <FormLabel htmlFor="send"
              sx={{
                ...webStyle.input_label,
                '&.Mui-focused': {
                  color: 'rgba(0, 0, 0, 0.6)',
                },
              }}
            >
              {configJSON.portalActivated}
            </FormLabel>
            <RadioGroup
              data-test-id="vendorPortalId"
              value={this.state.portalActive}
              aria-labelledby="portal"
              name="portal"
              onChange={this.handlePortalsChange}
            >
              <FormControlLabel
                value="yes"
                control={<Radio sx={{ color: "rgba(0, 0, 0, 0.6)", '&.Mui-checked': { color: "#CC9200" } }} />}
                label="Yes"
              />
              <FormControlLabel
                value="no"
                control={<Radio sx={{ color: "rgba(0, 0, 0, 0.6)", '&.Mui-checked': { color: "#CC9200" } }} />}
                label="No"
              />
            </RadioGroup>
          </FormControl>
        </Box>
    </Box>
    );
  }

  renderFilters = () => {
    return (
      <FilterContainer>
        {[...this.state.statusChips, ...this.state.chips].map((chip, chipIndex) => (
          <FilterChips key={chipIndex}>
            <FilterClose
              data-test-id={`removedChipId,${chipIndex}`}
              onClick={() => this.removeChip(chipIndex)}
            />
            <FilterDetails>{chip}</FilterDetails>
          </FilterChips>
        ))}
      </FilterContainer>
    )
  }

  renderPopupMessage = () => {
    return (
      <PopupMessage
        data-test-id="popupId"
        open={this.state.messagesPopup}
        type={this.state.messageType}
        handleClose={this.handlePopupClose}
        message={this.state.messagesForPopup}
      />
    )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <WorkOrderStyle>
        <NavBarArea>
          <NavigationSidebar
            data-test-id={"vendorId"}
            activeItem={this.state.activeVendorItem}
            sidebarItems={[
              { label: configJSON.dashboardOrder, labelKey: configJSON.dashboardOrderlabel, icon: dashboardLandLogo, pathName: configJSON.dashboardOrderlabel, activeIcon: activeMainDashboard },
              { label: configJSON.myAccountOrder, labelKey: configJSON.settingOrderlabel, icon: accountsMain, pathName: configJSON.myAccountOrderabel, activeIcon: activeMainAccount },
              { label: configJSON.workOrder, labelKey: configJSON.worksOrderlabel, icon: ordersMain, pathName: configJSON.worksOrderlabel, activeIcon: activeMainOrder },
              { label: configJSON.preferredOrder, labelKey: configJSON.prevendorOrderlabel, icon: vendorMain, pathName: configJSON.prevendorOrderlabel, activeIcon: activeMainVendor },
              { label: configJSON.remindersOrder, labelKey: configJSON.reminOrderlabel, icon: remindersMain, pathName: configJSON.reminOrderlabel, activeIcon: activeMainReminder },
              { label: configJSON.mypropOrder, labelKey: configJSON.mypropOrderlabel, icon: propertyMain, pathName: configJSON.mypropOrderlabel, activeIcon: activePropertyMain },
              { label: configJSON.financiaOrder, labelKey: configJSON.financOrderlabel, icon: financialMain, pathName: configJSON.financOrderlabel, activeIcon: activeMainFinancial },
              { label: configJSON.reportOrder, labelKey: configJSON.reportOrderlabel, icon: reportMain, pathName: configJSON.reportOrderlabel, activeIcon: activeMainReport },
              { label: configJSON.chatOrder, labelKey: configJSON.chatOrderlabel, icon: keyMainChain, pathName: configJSON.chatOrderlabel, activeIcon: activeKeyMain },
              { label: configJSON.settingOrder, icon: settingsMain, activeIcon: activeMainSetting, pathName: configJSON.settingOrderlabel, labelKey: configJSON.workOrder, }
            ]}
            onClickSidebar={this.handleVendoritemClick}
            keasylogo={keasyIcon}
            openDrawer={this.state.openVendorDrawer}
          onClickDrawer={this.handleVendorDrawer}
        />
        </NavBarArea>
        <TaskArea display={"flex"} flexDirection={"column"} justifyContent={"space-between"} width={"100%"}
         >
          <Box sx={{ position: "relative", paddingBottom: "10px" }}>
            <ViewHexIcon
              src={viewHexagonIcon}
              alt="Description"
            />
            <MainWorkContainer>
              <WorkStyles>
                <WorkOrderHead data-test-id="seeTextId">
                  {configJSON.preferredOrder}
                </WorkOrderHead>
                <Grid container spacing={2}>
                  <Grid item md={6} xs={12}>
                    <Box className="search-contents">
                      <img
                        className="search-icons"
                        src={SearchBarIcon} alt="location" />
                      <InputBase
                        data-test-id="searchBarId"
                        className="search-inputs"
                        placeholder="Search"
                        inputProps={{ "aria-label": "search" }}
                        value={this.state.searchVendor}
                        onChange={(searchEvent) => this.handleVendorSearchBar(searchEvent)}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6} justifyContent="flex-start">
                    <Box display={"flex"} gap={2}>
                    <Box className="search-main-content">
                      <Button data-test-id="propertyClickId" className="properties-dropdown" onClick={this.handleFilterClick}>
                        <Typography data-test-id="propertyNameId" className="text-data">{configJSON.filterName}</Typography>
                        <KeyboardArrowDownIcon />
                      </Button>
                    </Box>
                    <Box className="search-main-content">
                      <Button data-test-id="statusClickId" className="properties-dropdown" onClick={this.handleStatusFilter}>
                        <Typography data-test-id="propertyNameId" className="text-data">{configJSON.textStatus}</Typography>
                        <KeyboardArrowDownIcon />
                      </Button>
                    </Box>
                    </Box>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Box className="filter-content">
                      <Box className="filter">
                        <img
                          className="icon"
                          src={filterImageIcon} alt="filter" />
                        <Typography className="filter-data" data-test-id="headClickId">{configJSON.filters}{configJSON.results}</Typography>
                      </Box>
                      <FiltersWeb>
                        {this.renderFilters()}
                      </FiltersWeb>
                      <Typography className="clear-data" data-test-id="clearOrderId" onClick={this.clearChip}>{configJSON.clear}</Typography>
                    </Box>
                  </Grid>
                  <FiltersMobile>
                    {this.renderFilters()}
                  </FiltersMobile>
                </Grid>
                <>
                  <Box>
                    <Box data-test-id="navigationId" className="new-property">
                      <Button className="new-vendor" data-test-id="addId" onClick={this.handleClosePreferredVendor}>
                        <img
                          className="icon"
                          src={AddPlusIcon} alt="plus" />
                        <Typography  data-test-id="vendorDetailsId" className="new-data">{configJSON.addVendor}</Typography>
                      </Button>
                    </Box>
                    <BorderContainer>
                      <TableContainer>
                        <Table>
                          <TableHead>
                            <TableRow>
                              {this.renderSortVendorTableCell("company_name", "Company name")}
                              {this.renderSortVendorTableCell("vendor_type", "Type")}
                              <CustomTableCell>{configJSON.contactName}</CustomTableCell>
                              <CustomTableCell>{configJSON.vendorStatus}</CustomTableCell>
                              <CustomTableCell>{configJSON.email}</CustomTableCell>
                              <CustomTableCell>{configJSON.phone}</CustomTableCell>
                              <TableCell />
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {this.sortingData(this.state.vendorShowAll).map((vendorDetails: PreferredVendor, vendorIndex: number) => {
                              return (
                                <React.Fragment>
                                  <TableRow className={this.handleDetailsContainer(this.state.vendorShowAll)}>
                                    <CustomDetailTableCell>{vendorDetails.company_name ? vendorDetails.company_name: "-"}</CustomDetailTableCell>
                                    <CustomTableCells data-test-id="createTestId">{this.handleEmptyDetails(vendorDetails.vendor_type)}</CustomTableCells>
                                    <CustomDetailTableCell>{this.handleEmptyDetails(vendorDetails.name)}</CustomDetailTableCell>
                                    <CustomDetailTableCell>
                                      <OrderStatus data-test-id="statusId" sx={this.statusHandler(vendorDetails.status)}>
                                        {this.handleEmptyDetails(vendorDetails.status)}
                                      </OrderStatus>
                                    </CustomDetailTableCell>
                                    <CustomDetailTableCell>{this.handleEmptyDetails(vendorDetails.email_address)}</CustomDetailTableCell>
                                    <CustomDetailTableCell>{this.handleEmptyDetails(vendorDetails.phone_number)}</CustomDetailTableCell>
                                    <TableCell>
                                      <MoreArrow>
                                        <IconButton data-test-id="expandClickTestId" onClick={() => this.handleExpandVendor(vendorIndex)}>
                                          {this.state.expanded === vendorIndex ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                                        </IconButton>
                                      </MoreArrow>
                                    </TableCell>
                                  </TableRow>
                                  {this.state.expanded === vendorIndex && (
                                    <TableRow>
                                      <TableCell colSpan={7}>
                                        <InnerTableBg>
                                          <OngoingName>
                                            {configJSON.vendorWorks}
                                          </OngoingName>
                                          <Grid container spacing={2}>
                                            <Grid item xl={8} lg={8} sm={12} xs={12}>
                                              <TableContainer className={this.handleVendorAudit(vendorDetails)}>
                                                <Table>
                                                  <TableHead>
                                                    <TableRow>
                                                      {this.renderSortVendorTableCell("status", "Status")}
                                                      {this.renderSortVendorTableCell("location", "Location")}
                                                      <DetailsTableCell  data-test-id="locationId">{configJSON.details}</DetailsTableCell>
                                                      {this.renderSortVendorTableCell("cost", "Cost")}
                                                      {this.renderSortVendorTableCell("Resolved date", "Resolved date")}
                                                      <TableCell />
                                                    </TableRow>
                                                  </TableHead>
                                                  <CustomTableContainer>
                                                    {this.sortAuditingData(vendorDetails.vendor_work_orders).map((vendorWork: VendorWorks, workIndex: number)=>{
                                                      return(
                                                        <React.Fragment key={workIndex}>
                                                        <TableRow className={this.handleAuditEnable(vendorDetails.vendor_work_orders)}>
                                                            <StatusTableCell>
                                                              <OrderStatus  sx={this.statusHandler(vendorWork.status)}>{vendorWork.status}
                                                              </OrderStatus>
                                                            </StatusTableCell>
                                                          <StatusTableCell>{vendorWork.location.property} - {vendorWork.location.unit}</StatusTableCell>
                                                          <StatusTablesCell>{vendorWork.description}</StatusTablesCell>
                                                          <StatusTableCells>{configJSON.costValue}</StatusTableCells>
                                                          <StatusTableCell>{this.handleEmptyDetails(vendorWork.due_date)}</StatusTableCell>
                                                            <ViewCell>
                                                              <ViewBox data-test-id="viewId" display={"flex"} alignItems={"center"}  onClick={() => this.handleViewWorks(vendorWork.id)}>
                                                                {configJSON.viewWorkOrder}<CustomChevronRight />
                                                              </ViewBox>
                                                            </ViewCell>
                                                          </TableRow>
                                                        </React.Fragment>
                                                      )
                                                    })}
                                                  </CustomTableContainer>
                                                  <TableRow className={this.handleEmptyDisable(vendorDetails.vendor_work_orders)}>
                                                    <TableCell colSpan={6} align="center">
                                                      {configJSON.noFound}
                                                    </TableCell>
                                                  </TableRow>
                                                </Table>
                                              </TableContainer>
                                            </Grid>
                                            <Grid item xl={4} lg={4} sm={12} xs={12}>
                                              <Box display={"flex"} gap={2}>
                                                <Box display={"flex"} gap={"0.3rem"} alignItems={"center"} data-test-id="editVendorId" onClick={() => this.handleVendorInfo("Edit", vendorDetails.id)}>
                                                  <img src={pencilIcon} alt="edit" />
                                                  <Typography sx={webStyle.commonStylevendorsagain} data-test-id="editId">{configJSON.editVendor}</Typography>
                                                </Box>
                                                <Box display={"flex"} gap={"0.3rem"} alignItems={"center"} data-test-id="infoVendorId" onClick={() => this.handleVendorInfo("", vendorDetails.id)}>
                                                  <img src={vendorInfoImageIcon} alt="info" />
                                                  <Typography sx={webStyle.commonStylevendorsagain} data-test-id="infoId">{configJSON.vendorInfo}</Typography>
                                                </Box>
                                              </Box>
                                            </Grid>
                                          </Grid>
                                        </InnerTableBg>
                                      </TableCell>
                                    </TableRow>
                                  )}
                                </React.Fragment>
                              )}
                            )}
                          </TableBody>
                          {this.state.openTaskLoader ? "" :
                            <TableRow className={this.handleEmptyContainer(this.state.vendorShowAll)}>
                              <TableCell colSpan={6} align="center">
                                {configJSON.preferreNot}
                              </TableCell>
                            </TableRow>
                          } 
                        </Table>
                      </TableContainer>
                    </BorderContainer>
                    <Menu
                      data-test-id="electricFilter"
                      id="property-menu"
                      keepMounted
                      open={this.state.openFilter}
                      elevation={0}
                      anchorEl={this.state.anchorEl}
                      onClose={this.handleFilterClose}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right"
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "center"
                      }}
                      slotProps={{
                        paper: {
                          style: { width: "200px", height: "200px", boxShadow: "rgba(0, 0, 0, 0.15) 0px 2px 8px", marginLeft: "-2rem", marginTop: "0.5rem" }
                        }
                      }}
                    >
                      {this.state.vendorTypes.map((items: CatType) => {
                          return (
                            items.attributes.name === "Vendor Types" && (
                              items.attributes.sub_categories.map((item: SubCategory, itemIndex) => {
                                return (
                                  <CustomMenuItem
                                    key={itemIndex}
                                    data-test-id="propertyMenuItem"
                                    onClick={()=>this.handleFilters(item.name)}
                                  >
                                    <CustomListItemText primaryTypographyProps={{ fontSize: "14px" }} primary={item.name} />
                                  </CustomMenuItem>
                                )
                              })
                            )
                          )
                        })
                      }
                    </Menu>
                  </Box>
                  <CustomModal
                    data-test-id="venListModalId"
                    open={this.state.preferredVendorModal}
                  >
                    <ParagraphContainer>
                      {this.renderPopupMessage()}
                      <Box display={"flex"} flexDirection={"column"} gap={"10px"} height={"100%"} width={"100%"}>
                        <CloseModal onClick={this.handleClosePreferredVendor}>
                          <Close />
                        </CloseModal>
                          <KeyHeading data-test-id="addNewVendorId" onClick={this.handleAddNewVendor}>
                            {configJSON.addNewVendor}
                          </KeyHeading>
                        <KeyContainer>
                          <Box display={"flex"} flexDirection={"column"} gap={"10px"} alignItems={"center"} width={"100%"}>
                            <Button data-test-id="preVendorsId" className="properties-dropdown" onClick={this.handleVendorClick}>{configJSON.vendorSelected}</Button>
                            <Menu
                              data-test-id="listId"
                              id="property"
                              keepMounted
                              open={this.state.openExistingVendors}
                              elevation={0}
                              anchorEl={this.state.anchorsVendorEl}
                              onClose={this.handleCloseExistingVendors}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "center"
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "center"
                              }}
                              slotProps={{
                                paper: {
                                  style: { overflow: "auto", width: "60%", height: "280px", boxShadow: "rgba(0, 0, 0, 0.15) 0px 2px 8px", marginTop: "0.5rem" }
                                }
                              }}
                            >
                              <Box
                                data-test-id="vendorsItem">
                                <CustomTableContainer>
                                  <Table>
                                    <TableHead>
                                      <TableRow>
                                        <DetailsCell>{configJSON.companyName}</DetailsCell>
                                        <DetailsCell>{configJSON.vendorType}</DetailsCell>
                                        <DetailsCell>{configJSON.contactName}</DetailsCell>
                                        <DetailsCell>{configJSON.vendorStatus}</DetailsCell>
                                        <DetailsCell>{configJSON.email}</DetailsCell>
                                        <DetailsCell>{configJSON.phone}</DetailsCell>
                                        <TableCell />
                                      </TableRow>
                                    </TableHead>
                                    <CustomContainer>
                                      {this.state.existingVendors.map((oldVendors: VendorList, oldVendorsIndex: number) => {
                                        return (
                                          <React.Fragment key={oldVendorsIndex}>
                                            <TableRow>
                                              <StatusCell data-test-id="sortId">{this.handleEmptyDetails(oldVendors.company_name)}</StatusCell>
                                              <StatusCell>{this.handleEmptyDetails(oldVendors.vendor_type)}</StatusCell>
                                              <StatusCell>{this.handleEmptyDetails(oldVendors.name)}</StatusCell>
                                              <StatusCell>
                                                <OrderStatus data-test-id="statusId" sx={this.statusHandler(oldVendors.status)}>
                                                  {this.handleEmptyDetails(oldVendors.status)}
                                                </OrderStatus>
                                              </StatusCell>
                                              <StatusCell>{this.handleEmptyDetails(oldVendors.email_address)}</StatusCell>
                                              <StatusCell>{this.handleEmptyDetails(oldVendors.phone_number)}</StatusCell>
                                              <ViewCell>
                                                <AddButton disabled={this.state.disabledVendorStates[oldVendors.id] || false}  data-test-id="addButtonId" onClick={() => this.handleAddExistingVendor(oldVendors.id)}>
                                                  {configJSON.addExistingButton}
                                                </AddButton>
                                              </ViewCell>
                                            </TableRow>
                                          </React.Fragment>
                                        );
                                      })}
                                    </CustomContainer>
                                  </Table>
                                </CustomTableContainer>
                              </Box>
                            </Menu>
                          </Box>
                        </KeyContainer>
                      </Box>
                    </ParagraphContainer>
                  </CustomModal>
                  <Menu
                    data-test-id="statusFilterId"
                    id="property-menu"
                    keepMounted
                    open={this.state.openStatusFilter}
                    elevation={0}
                    anchorEl={this.state.anchorStatusEl}
                    onClose={this.handleStatusFilterClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right"
                    }}
                    slotProps={{
                      paper: {
                        style: { width: "210px", boxShadow: "rgba(0, 0, 0, 0.15) 0px 2px 8px", marginLeft: "-2rem", marginTop: "0.5rem" }
                      }
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center"
                    }}
                  >
                    {this.state.vendorStatus.map((status: string, statusIndex: number) => {
                      return (
                        <CustomMenuItem
                          key={statusIndex}
                          data-test-id="propertyMenuItem"
                          onClick={() => this.handleStatusFilters(status)}
                        >
                          <CustomListItemText primaryTypographyProps={{ fontSize: "14px" }} primary={status} />
                        </CustomMenuItem>
                      )
                    })}
                  </Menu>
                </>
              </WorkStyles>
              <Box className="bgImgWrapper" data-test-id="bgImgId">
                <Box className="backgroundImage">
                  <img src={bottomImageIcon} alt="hexagon-one" className="hexImg" />
                </Box>
              </Box>
            </MainWorkContainer>
          </Box>
        </TaskArea>
        <React.Fragment>
          <Dialog
            maxWidth={false}
            sx={{
              "& .MuiDialog-paper": {
                maxWidth: "1151px",
                width: "100%",

              },
            }}
            open={this.state.open}
            data-test-id='openDialog'
            aria-labelledby="responsive-dialog-title"
          >
            <DialogTitle id="responsive-dialog-title">
              <DialogImg aria-label="close" onClick={() => this.handleVendorInfo("", 0)}
                src={closeIcon} />
            </DialogTitle>
            {this.state.vendorInformation.map((vendorInfo: PreferredVendor, infoIndex: number) => {
              return (
                <CustomDialogContent key={infoIndex}>
                  <Grid container>
                    <Grid item md={6}
                      sx={{
                        padding: "3rem",
                        "@media (max-width: 600px)": {
                          padding: 0,
                        }
                      }}
                    >
                      <Grid container>
                        <Grid item md={6} xs={6}>
                          <Box>
                            <Typography style={webStyle.modalTitle}>{configJSON.modalTitle}</Typography>
                          </Box>
                        </Grid>
                        <Grid item md={6} xs={6}>
                          <Box style={webStyle.titleStyle} display={"none"}>
                            <CustomIconButton size="small" data-test-id="edit">
                            </CustomIconButton>
                          </Box>
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item md={6} xs={7}>
                        <Typography sx={webStyle.modalKeyStyle}>{configJSON.textName}</Typography>
                        </Grid>
                        <Grid item md={6} xs={5}>
                        <Typography sx={webStyle.modalValueStyle}>{this.handleEmptyDetails(vendorInfo.name)}</Typography>
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item md={6} xs={7}>
                        <Typography sx={webStyle.modalKeyStyle}>{configJSON.vendorType}</Typography>
                        </Grid>
                        <Grid item md={6} xs={5}>
                        <Typography sx={webStyle.modalValueStyle}>{vendorInfo.vendor_type}</Typography>
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item md={6} xs={7}>
                        <Typography sx={webStyle.modalKeyStyle}>{configJSON.vendorTrade}</Typography>
                        </Grid>
                        <Grid item md={6} xs={5}>
                        <Typography sx={webStyle.modalValueStyle}>{this.handleEmptyDetails(this.handleVendorTrade(vendorInfo.vendor_trade))}</Typography>
                        </Grid>
                      </Grid>
                      <Grid container marginTop={"2rem"}>
                        <Grid item md={6} xs={6}>
                          <Box>
                            <Typography sx={webStyle.modalTitle}>{configJSON.contact}</Typography>
                          </Box>
                        </Grid>
                        <Grid item md={6} xs={6}>
                          <Box sx={webStyle.titleStyle}>
                            <CustomIconButton data-test-id="editContactId" size="small" onClick={()=>this.handleEditClick(vendorInfo)}>
                              <Box display={"flex"} gap={1}>
                                {this.state.editable ?
                                  <img src={pencilIcon} alt="edit" />
                                  : ""}
                                {this.state.editable}
                              </Box>
                            </CustomIconButton>
                          </Box>
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item md={6} xs={7}>
                        <Typography sx={webStyle.modalKeyStyle}>{configJSON.phoneNumbers}</Typography>
                        </Grid>
                        <Grid item md={6} xs={5}>
                        <Typography sx={webStyle.modalValueStyle}>{configJSON.mobile}{this.formatAndExtractPhoneNumber(vendorInfo.phone_number)}</Typography>
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item md={6} xs={7}>
                        <Typography sx={webStyle.modalKeyStyle}>{configJSON.email}</Typography>
                        </Grid>
                        <Grid item md={6} xs={5}>
                        <Typography sx={webStyle.modalValueStyle}>{this.handleEmptyDetails(vendorInfo.email_address)}</Typography>
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item md={6} xs={7}>
                        <Typography sx={webStyle.modalKeyStyle}>{configJSON.address}</Typography>
                        </Grid>
                        <Grid item md={6} xs={5}>
                        <Typography sx={webStyle.modalValueStyle}>{this.handleEmptyDetails(vendorInfo.address)}</Typography>
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item md={6} xs={7}>
                        <Typography sx={webStyle.modalKeyStyle}>{configJSON.portalActivated}</Typography>
                        </Grid>
                        <Grid item md={6} xs={5}>
                        <Typography sx={webStyle.modalValueStyle}>{this.handleSend(vendorInfo.portal_activated)}</Typography>
                        </Grid>
                      </Grid>
                      <Grid container marginTop="2rem">
                        <Grid item md={6} xs={6}>
                          <Box>
                            <Typography sx={webStyle.modalTitle}>{configJSON.federalTax}</Typography>
                          </Box>
                        </Grid>
                        <Grid item md={6} xs={6}>
                          <Box sx={webStyle.titleStyle} display={"none"}>
                            <CustomIconButton size="small">
                            </CustomIconButton>
                          </Box>
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item md={6} xs={7}>
                        <Typography sx={webStyle.modalKeyStyle}>{configJSON.taxpayerName}</Typography>
                        </Grid>
                        <Grid item md={6} xs={5}>
                        <Typography sx={webStyle.modalValueStyle}>{this.handleEmptyDetails(vendorInfo.taxpayer_name)}</Typography>
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item md={6} xs={7}>
                        <Typography sx={webStyle.modalKeyStyle}>{configJSON.taxpayerID}</Typography>
                        </Grid>
                        <Grid item md={6} xs={5}>
                        <Typography sx={webStyle.modalValueStyle}>{this.formatNumber(vendorInfo.taxpayer_id)}</Typography>
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item md={6} xs={7}>
                        <Typography sx={webStyle.modalKeyStyle}>{configJSON.send}</Typography>
                        </Grid>
                        <Grid item md={6} xs={5}>
                        <Typography sx={webStyle.modalValueStyle}>{this.handleSend(vendorInfo.send_1099)}</Typography>
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item md={6} xs={7}>
                        <Typography sx={webStyle.modalKeyStyle}>{configJSON.formW}</Typography>
                        </Grid>
                        <Grid item md={6} xs={5}>
                        <Typography sx={webStyle.modalValueStyle}>{vendorInfo.form_w9.length >0 ? vendorInfo.form_w9.map((form: FormW, formIndex: number) => {
                            return (
                              <Box key={formIndex} display={"flex"} gap={"1rem"}>
                                <img src={DefaultImgPlcIcon} alt="default" />
                                <Typography sx={webStyle.modalValueStyle}>{form.name}</Typography>
                              </Box>
                            )
                          }): configJSON.slash}</Typography>
                        </Grid>
                      </Grid>
                      <Grid container marginTop="2rem" >
                        <Grid item md={6} xs={6}>
                          <Box>
                            <Typography sx={webStyle.modalTitle}>{configJSON.onlinePayables}</Typography>
                          </Box>
                        </Grid>
                        <Grid item md={6} xs={6}>
                          <Box sx={webStyle.titleStyle} display={"none"}>
                            <CustomIconButton size="small">
                            </CustomIconButton>
                          </Box>
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item md={6} xs={7}>
                        <Typography sx={webStyle.modalKeyStyle}>{configJSON.useOnlinePayables}</Typography>
                        </Grid>
                        <Grid item md={6} xs={5}>
                        <Typography sx={webStyle.modalValueStyle}>{this.handleSend(vendorInfo.use_online_payables)}</Typography>
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item md={6} xs={7}>
                        <Typography sx={webStyle.modalKeyStyle}>{configJSON.paymentType}</Typography>
                        </Grid>
                        <Grid item md={6} xs={5}>
                        <Typography sx={webStyle.modalValueStyle}>{vendorInfo.payment_type}</Typography>
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item md={6} xs={7}>
                        <Typography sx={webStyle.modalKeyStyle}>{configJSON.vendorBankRoutingNumber}</Typography>
                        </Grid>
                        <Grid item md={6} xs={5}>
                        <Typography sx={webStyle.modalValueStyle}>{this.maskNumber(vendorInfo.vendor_bank_routing_number)}</Typography>
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item md={6} xs={7}>
                        <Typography sx={webStyle.modalKeyStyle}>{configJSON.vendorBankAccountNumber}</Typography>
                        </Grid>
                        <Grid item md={6} xs={5}>
                        <Typography sx={webStyle.modalValueStyle}>{this.maskNumber(vendorInfo.vendor_bank_account_number)}</Typography>
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item md={6} xs={7}>
                        <Typography sx={webStyle.modalKeyStyle}>{configJSON.lastPaymentPreference}<br /> {configJSON.updatedDate}</Typography>
                        </Grid>
                        <Grid item md={6} xs={5}>
                        <Typography sx={webStyle.modalValueStyle}>{this.handleEmptyDetails(vendorInfo.last_payment_preference_updated_at)}</Typography>
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item md={6} xs={7}>
                        <Typography sx={webStyle.modalKeyStyle}>{configJSON.lastPaymentPreference}<br /> {configJSON.updatedBy}</Typography>
                        </Grid>
                        <Grid item md={6} xs={5}>
                        <Typography sx={webStyle.modalValueStyle}>{this.handleEmptyDetails(vendorInfo.last_payment_preference_updated_by)}</Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item md={6}
                      sx={{
                        padding: "3rem",
                        "@media (max-width: 600px)": {
                          padding: 0,
                        }
                      }}
                    >
                      <Grid container >
                        <Grid item md={6} xs={7}>
                          <Box>
                            <Typography style={webStyle.modalTitle}>{configJSON.compliance}</Typography>
                          </Box>
                        </Grid>
                        <Grid item md={6} xs={5}>
                          <Box sx={webStyle.titleStyle}>
                            <CustomIconButton size="small">
                            </CustomIconButton>
                          </Box>
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item md={6} xs={7}>
                        <Typography style={webStyle.modalKeyStyle}>{configJSON.workersComp}</Typography>
                        </Grid>
                        <Grid item md={6} xs={6}>
                          {vendorInfo.business_licenses.map((busLicense: FormW, busLicenseIndex: number) => (
                            <EditGrid container key={busLicenseIndex} className={this.handleEditDetails(vendorInfo.business_licenses)}>
                              <Grid item md={10} xs={10}>
                                <Box display={"flex"} gap={"1rem"}>
                                  <img src={DefaultImgPlcIcon} alt="default" />
                                  <Typography sx={webStyle.modalValueStyle}>{busLicense.name}</Typography>
                                </Box>
                              </Grid>
                              <Grid item md={2} xs={2}>
                                <Typography sx={webStyle.downloadstyle}>{configJSON.downloads}</Typography>
                              </Grid>
                            </EditGrid>
                          ))
                          }
                          <Typography sx={webStyle.modalValueStyle} className={this.handleEmptyDisabled(vendorInfo.business_licenses)}>{configJSON.slash}</Typography>
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item md={6} xs={7}>
                        <Typography style={webStyle.modalKeyStyle}>{configJSON.generalExpiration}</Typography>
                        </Grid>
                        <Grid item md={6} xs={5}>
                        <Typography sx={webStyle.modalValueStyle}>{this.handleEmptyDetails(vendorInfo.business_license_exp_date)}</Typography>
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item md={6} xs={7}>
                        <Typography style={webStyle.modalKeyStyle}>{configJSON.ePAExpiration}</Typography>
                        </Grid>
                        <Grid item md={6} xs={6}>
                            {vendorInfo.business_insurances.map((busInsurance: FormW, busInsuranceIndex: number) => (
                              <EditGrid container key={busInsuranceIndex} className={this.handleEditDetails(vendorInfo.business_licenses)}>
                                <Grid item md={10} xs={10}>
                                  <Box display={"flex"} gap={"1rem"}>
                                    <img src={DefaultImgPlcIcon} alt="default" />
                                    <Typography sx={webStyle.modalValueStyle}>{busInsurance.name}</Typography>
                                  </Box>
                                </Grid>
                                <Grid item md={2} xs={2}>
                                  <Typography sx={webStyle.downloadstyle} >{configJSON.downloads}</Typography>
                                </Grid>
                              </EditGrid>
                            ))
                          }
                            <Typography sx={webStyle.modalValueStyle} className={this.handleEmptyDisabled(vendorInfo.business_insurances)}>{configJSON.slash}</Typography>
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item md={6} xs={7}>
                        <Typography style={webStyle.modalKeyStyle}>{configJSON.autoExpiration}</Typography>
                        </Grid>
                        <Grid item md={6} xs={5}>
                        <Typography sx={webStyle.modalValueStyle}>{this.handleEmptyDetails(vendorInfo.business_insurance_exp_date)}</Typography>
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item md={6} xs={7}>
                        <Typography style={webStyle.modalKeyStyle}>{configJSON.contractExpiration}</Typography>
                        </Grid>
                        <Grid item md={6} xs={6}>
                            {vendorInfo.epa_certification_licenses.map((epaLicense: FormW, epaLicenseIndex: number) => (
                              <EditGrid container key={epaLicenseIndex} className={this.handleEditDetails(vendorInfo.epa_certification_licenses)}>
                                <Grid item md={10} xs={10}>
                                  <Box display={"flex"} gap={"1rem"}>
                                    <img src={DefaultImgPlcIcon} alt="default" />
                                    <Typography sx={webStyle.modalValueStyle}>{epaLicense.name}</Typography>
                                  </Box>
                                </Grid>
                                <Grid item md={2} xs={2}>
                                  <Typography sx={webStyle.downloadstyle}>{configJSON.downloads}</Typography>
                                </Grid>
                              </EditGrid>
                            ))
                            }
                            <Typography sx={webStyle.modalValueStyle} className={this.handleEmptyDisabled(vendorInfo.epa_certification_licenses)}>{configJSON.slash}</Typography>
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item md={6} xs={7}>
                          <Typography sx={webStyle.modalKeyStyle}>{configJSON.insurancesPaperWork}</Typography>
                        </Grid>
                        <Grid item md={6} xs={5}>
                          <Typography sx={webStyle.modalValueStyle}>{this.handleEmptyDetails(vendorInfo.epa_license_exp_date)}</Typography>
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item md={6} xs={7}>
                          <Typography sx={webStyle.modalKeyStyle}>{configJSON.expirationDate}</Typography>
                        </Grid>
                        <Grid item md={6} xs={6}>
                            {vendorInfo.epa_other_certificates.map((epaOther: FormW, epaOtherIndex: number) => (
                              <EditGrid container key={epaOtherIndex} className={this.handleEditDetails(vendorInfo.epa_other_certificates)}>
                                <Grid item md={10} xs={10}>
                                  <Box display={"flex"} gap={"1rem"}>
                                    <img src={DefaultImgPlcIcon} alt="default" />
                                    <Typography sx={webStyle.modalValueStyle}>{epaOther.name}</Typography>
                                  </Box>
                                </Grid>
                                <Grid item md={2} xs={2}>
                                  <Typography sx={webStyle.downloadstyle}>{configJSON.downloads}</Typography>
                                </Grid>
                              </EditGrid>
                            ))
                          }
                            <Typography sx={webStyle.modalValueStyle} className={this.handleEmptyDisabled(vendorInfo.epa_other_certificates)}>{configJSON.slash}</Typography>
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item md={6} xs={7}>
                          <Typography sx={webStyle.modalKeyStyle}>{configJSON.otherExpiration}</Typography>
                        </Grid>
                        <Grid item md={6} xs={5}>
                          <Typography sx={webStyle.modalValueStyle}>{this.handleEmptyDetails(vendorInfo.other_certificate_exp_date)}</Typography>
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item md={6} xs={7}>
                          <Typography sx={webStyle.modalKeyStyle}>{configJSON.insuranceLicensing}</Typography>
                        </Grid>
                        <Grid item md={6} xs={6}>
                            {vendorInfo.state_licenses.map((stateLicense: FormW, stateLicenseIndex: number) => (
                              <EditGrid container key={stateLicenseIndex} className={this.handleEditDetails(vendorInfo.state_licenses)}>
                                <Grid item md={10} xs={10}>
                                  <Box display={"flex"} gap={"1rem"}>
                                    <img src={DefaultImgPlcIcon} alt="default" />
                                    <Typography sx={webStyle.modalValueStyle}>{stateLicense.name}</Typography>
                                  </Box>
                                </Grid>
                                <Grid item md={2} xs={2}>
                                  <Typography sx={webStyle.downloadstyle}>{configJSON.downloads}</Typography>
                                </Grid>
                              </EditGrid>
                            ))
                          }
                          <Typography sx={webStyle.modalValueStyle} className={this.handleEmptyDisabled(vendorInfo.state_licenses)}>{configJSON.slash}</Typography>
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item md={6} xs={7}>
                          <Typography sx={webStyle.modalKeyStyle}>{configJSON.expirationsDate}</Typography>
                        </Grid>
                        <Grid item md={6} xs={5}>
                          <Typography sx={webStyle.modalValueStyle}>{this.handleEmptyDetails(vendorInfo.state_license_exp_date)}</Typography>
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item md={6} xs={7}>
                          <Typography sx={webStyle.modalKeyStyle}>{configJSON.stateInsurance}</Typography>
                        </Grid>
                        <Grid item md={6} xs={6}>
                            {vendorInfo.state_insurances.map((stateInsurance: FormW, stateInsuranceIndex: number) => (
                              <EditGrid container key={stateInsuranceIndex} className={this.handleEditDetails(vendorInfo.state_insurances)}>
                                <Grid item md={10} xs={10}>
                                  <Box display={"flex"} gap={"1rem"}>
                                    <img src={DefaultImgPlcIcon} alt="default" />
                                    <Typography sx={webStyle.modalValueStyle}>{stateInsurance.name}</Typography>
                                  </Box>
                                </Grid>
                                <Grid item md={2} xs={2}>
                                  <Typography sx={webStyle.downloadstyle}>{configJSON.downloads}</Typography>
                                </Grid>
                              </EditGrid>
                            ))
                            }
                            <Typography sx={webStyle.modalValueStyle} className={this.handleEmptyDisabled(vendorInfo.state_insurances)}>{configJSON.slash}</Typography>
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item md={6} xs={7}>
                          <Typography sx={webStyle.modalKeyStyle}>{configJSON.stateInsurancExpirations}</Typography>
                        </Grid>
                        <Grid item md={6} xs={5}>
                          <Typography sx={ webStyle.modalValueStyle }>{this.handleEmptyDetails(vendorInfo.state_insurance_exp_date)}</Typography>
                        </Grid>
                      </Grid>
                      <Grid container sx={{ marginTop: "2rem" }}>
                        <Grid item md={6} xs={6}>
                          <Box>
                            <Typography sx={webStyle.modalTitle}>{configJSON.notes}</Typography>
                          </Box>
                        </Grid>
                        <Grid item md={6} xs={6}>
                          <Box sx={webStyle.titleStyle}>
                            <CustomIconButton size="small">
                            </CustomIconButton>
                          </Box>
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item md={12}>
                          <Box>
                            <Typography sx={webStyle.modalTitle}>{this.handleEmptyDetails(vendorInfo.notes)}</Typography>
                          </Box>
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item md={12}>
                          <Box sx={webStyle.titleStyle}>
                            <Typography sx={webStyle.modalTitle}>{configJSON.attachments}</Typography>
                          </Box>
                        </Grid>
                      </Grid>
                      <Box >
                        <TableContainer >
                          <Table sx={{ borderCollapse: "collapse" }}>
                            <TableHead>
                              <TableRow>
                                <VendorTableCell sx={webStyle.nameliststyle}>{configJSON.textName}</VendorTableCell>
                                <VendorTableCell sx={webStyle.nameliststyle}>{configJSON.uploadedby}</VendorTableCell>
                                <VendorTableCell sx={webStyle.nameliststyle}>{configJSON.date}</VendorTableCell>
                                <VendorTableCell sx={webStyle.nameliststyle}>{configJSON.shared}</VendorTableCell>
                              </TableRow>
                            </TableHead>
                                <CustomTableContainer>
                                  {vendorInfo.attachments.map((attachDocs: AttachmentsForm, docsIndex: number) => {
                                    return (
                                      <React.Fragment key={docsIndex}>
                                        <TableRow className={this.handleAttachmentsEnable(vendorInfo.attachments)}>
                                          <StatusAttachCells>
                                            <Box display={"flex"} gap={1} alignItems={"center"}>
                                              <img src={DefaultImgPlcIcon} alt="default" />
                                              {attachDocs.name}
                                            </Box>
                                          </StatusAttachCells>
                                          <StatusAttachCells>{attachDocs.uploaded_by}</StatusAttachCells>
                                          <StatusAttachsCells>{attachDocs.date}</StatusAttachsCells>
                                          <StatusAttachsCells>{attachDocs.shared}</StatusAttachsCells>
                                          <ViewCell>
                                            <ViewBox data-test-id="viewId" display={"flex"} alignItems={"center"}>
                                              {configJSON.downloads}
                                            </ViewBox>
                                          </ViewCell>
                                        </TableRow>
                                      </React.Fragment>
                                    )
                                  })}
                            </CustomTableContainer>
                            <VendorTableCell colSpan={4} align="center" className={this.handleEmptyAttachDisable(vendorInfo.attachments)}>
                              {configJSON.noAddFound}
                            </VendorTableCell>
                          </Table>
                        </TableContainer>
                      </Box>
                    </Grid>
                  </Grid>
                </CustomDialogContent>
              )
            })}
            </Dialog>
          <Dialog data-test-id="dialogId" open={this.state.editModalOpen} onClose={this.handleCloseEditModal} fullWidth>
             {this.renderPopupMessage()}
            <CustomDialogTitle data-test-id="editNewId">{configJSON.editDetails}</CustomDialogTitle>
            <DialogContent>
                  {this.renderField()}
            </DialogContent>
            <DialogActions>
              <SubmitBtn data-test-id="submitId" onClick={this.handleUpdateBoxDetails}>{configJSON.submit}</SubmitBtn>
              <CancelButton onClick={this.handleCloseEditModal}>{configJSON.cancel}</CancelButton>
            </DialogActions>
          </Dialog>

        </React.Fragment>
        {this.renderPopupMessage()}
         <Loader loading={this.state.openTaskLoader}/>
      </WorkOrderStyle>
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const MainWorkContainer = styled(Box)({
  width: "100%",
  display: "flex",
  fontFamily: "Outfit",
  alignItems: "center",
  flexDirection: "column",
  "@media(max-width: 992px)": {
    padding: "0px 0px 0px 0px"

  }
});

const WorkOrderStyle = styled(Box)({
  justifyContent: "space-between",
  display: "flex",
  fontFamily: "Outfit",
  width: "100%",
  "@media(max-width: 992px)": {
    flexDirection: "column"
  },
  "&.webStyle": {
    backgroundColor: "red",
    fontFamily: "Outfit",
  },
  "& .bgImgWrapper": {
    justifyContent: "flex-end",
    width: "100%",
    display: "flex",
    fontFamily: "Outfit",
    marginTop: "2rem",
    "@media (max-width:992px)": {
      display: "none",
    }
  },
  "& .backgroundImage": {
    width: "100%",
    fontFamily: "Outfit",
    textAlign: "end",
    position: "absolute"
  },
  "& .hexImg": {
    objectFit: "cover"
  }
});

const CustomMenuItem = styled(MenuItem)({
  fontSize: "14px",
  color: "#000000",
  margin: "0px 5px 0px 5px",
  fontFamily: "Outfit",
  fontWeight: 400,
  "&:hover": {
    backgroundColor: "rgb(250,227,162)",
    margin: "0px 5px 0px 5px !important",
  },
  "@media(max-width: 475px)": {
    fontSize: "10px",
  },
  "&.changeColor": {
    borderRadius: "10px",
    backgroundColor: "rgb(250,227,162)",
    fontFamily: "Outfit",
  }
});

const CustomListItemText = styled(ListItemText)({
  fontWeight: 400,
  fontFamily: "Outfit",
  fontSize: "14px",
  "@media(max-width: 475px)": {
    width: "12px"
  }
});

const WorkOrderHead = styled(Typography)({
  color: "#000000",
  fontSize: "24px",
  fontFamily: "Outfit",
  fontWeight: 700,
  marginBottom: "20px"
});

const WorkStyles = styled(Box)({
  marginTop: "42px",
  width: "90%",
  "& .search-main-content": {
    display: "flex",
    fontFamily: "Outfit",
    gap: "1rem",
    "@media(max-width:600px)": {
      width: "100%",
      position: "relative",
      flexDirection: "row",
      height: "100%",
      display: "flex",
      "@media(max-width: 420px)": {
        gap: "5px"
      }
    },
  },
  "& .search-contents": {
    borderRadius: "50px",
    display: "flex",
    fontFamily: "Outfit",
    border: "1px solid #CBD5E1",
    paddingLeft: "15px",
    alignItems: "center",
    height: "44px",
    gap: "13px",
  },
  "& .search-icons": {
    height: "24px",
    width: "24px"
  },
  "& .search-text": {
    fontWeight: 400,
    color: "#A3978F",
    fontSize: "16px",
    fontFamily: "Outfit",
    "@media(max-width: 420px)": {
      fontSize: "12px"
    }
  },
  "& .search-inputs": {
    width: "100%"
  },
  "& .properties-dropdown": {
    width: "142px",
    justifyContent: "space-around",
    height: "44px",
    backgroundColor: "rgb(253,242,208)",
    display: "flex",
    textTransform: "none",
    fontFamily: "Outfit",
    borderRadius: "8px",
    padding: "2px 10px 2px 10px",
    cursor: "pointer",
    color: "#000000",
    "&:hover": {
      backgroundColor: "rgb(253,242,208)",
      color: "#000000"
    },
    "@media(max-width: 420px)": {
      width: "105px",
      height: "30px",
      fontSize: "12px",
    }
  },
  "& .view-completed": {
    width: "180px",
    backgroundColor: "rgb(253,242,208)",
    justifyContent: "space-around",
    height: "44px",
    display: "flex",
    fontFamily: "Outfit",
    alignItems: "center",
    borderRadius: "8px",
    cursor: "pointer",
    padding: "2px 10px 2px 10px",
    "@media(max-width: 460px)": {
      fontSize: "12px",
      width: "100%",
      height: "35px",
    },
    "@media(max-width: 347px)": {
      padding: "2px 5px 2px 5px"
    }
  },
  "& .icon": {
    mixBlendMode: "multiply"
  },
  "& .image-icon": {
    width: "16px",
    height: "16px"
  },
  "& .text-data": {
    fontSize: "16px",
    fontFamily: "Outfit",
    fontWeight: 700,
    "@media(max-width: 420px)": {
      fontSize: "12px"
    }
  },
  "& .new-data": {
    color: "#000000",
    fontSize: "18px",
    fontFamily: "Outfit",
    fontWeight: 700,
    "@media(max-width: 420px)": {
      fontSize: "12px"
    }
  },
  "& .new-property": {
    gap: "15px",
    fontFamily: "Outfit",
    paddingRight: "5px",
    alignItems: "center",
    borderRadius: "8px",
    display: "flex",
    justifyContent: "flex-end",
    paddingLeft: "5px"
  },
  "& .new-vendor": {
    backgroundColor: "rgb(253,242,208)",
    textTransform: "none",
    fontFamily: "Outfit",
    display: "flex",
    gap: "5px",
    "&:hover": {
      backgroundColor: "rgb(253,242,208)"
    }
  },
  "& .filter-content": {
    display: "flex",
    fontFamily: "Outfit",
    justifyContent: "space-between",
    alignItems: "center",
  },
  "& .filter": {
    fontFamily: "Outfit",
    alignItems: "center",
    display: "flex",
    gap: "15px",
  },
  "& .filter-data": {
    fontWeight: 700,
    fontFamily: "Outfit",
    fontSize: "12px",
    color: "#000000",
    whiteSpace: "nowrap"
  },
  "& .clear-data": {
    color: "#CC9200",
    fontFamily: "Outfit",
    fontSize: "12px",
    fontWeight: 700,
    cursor: "pointer",
    whiteSpace: "nowrap"
  },
  "& .isUnitsDisabled": {
    display: "none"
  }
});

const BorderContainer = styled(Box)({
  border: "1px solid #FFD466",
  padding: "1rem 1rem 0rem 1rem",
  flexDirection: "column",
  marginTop: "1rem",
  borderRadius: "8px 8px 32px 8px",
  display: "flex",
  "& .scrollable-container": {
    fontFamily: "Outfit",
    height: "300px",
    overflowY: "auto"
  },
  "& .isUnitsDisabled": {
    display: "none"
  }
});

const CustomTableCell = styled(TableCell)({
  color: "#A3978F",
  fontFamily: "Outfit",
  fontSize: "12px",
  fontWeight: 700,
  textAlign: "center",
  padding: "0px 10px 0px 10px !important",
  whiteSpace: "nowrap"
});

const CustomTableCells = styled(TableCell)({
  color: "#000000",
  fontFamily: "Outfit",
  fontSize: "12px",
  fontWeight: 700,
  textAlign: "center",
  padding: "0px 10px 0px 10px !important",
  whiteSpace: "nowrap"
});

const DetailsTableCell = styled(TableCell)({
  fontFamily: "Outfit",
  color: "#A3978F",
  textAlign: "center",
  fontWeight: 700,
  fontSize: "12px",
  whiteSpace: "nowrap"
});

const CustomDetailTableCell = styled(TableCell)({
  color: "#000000",
  fontWeight: 400,
  fontFamily: "Outfit",
  fontSize: "12px",
  textAlign: "center"
});

const OrderStatus = styled(Typography)({
  borderRadius: "50px",
  color: "#059669",
  textAlign: "center",
  fontFamily: "Outfit",
  whiteSpace: "nowrap",
  fontSize: "9px",
  padding: "4px 5px",
  fontWeight: 700,
  width: "fit-content"
});

const InnerTableBg = styled(Box)({
  border: "1px solid #F9FAFC",
  backgroundColor: "#F9FAFC",
  fontFamily: "Outfit",
  borderRadius: "10px",
  padding: "20px 0px 20px 20px"
});

const StatusTableCells = styled(TableCell)({
  fontSize: "12px",
  color: "#000000",
  fontWeight: 700,
  fontFamily: "Outfit",
  textAlign: "center"
});

const StatusAttachCells = styled(TableCell)({
  fontSize: "12px",
  color: "#000000",
  fontWeight: 400,
  fontFamily: "Outfit",
  textAlign: "left"
});

const StatusAttachsCells = styled(TableCell)({
  fontSize: "12px",
  fontFamily: "Outfit",
  color: "#000000",
  whiteSpace: "nowrap",
  fontWeight: 400,
  textAlign: "left"
});

const OngoingName = styled(Typography)({
  fontSize: "16px",
  color: "#1C1917",
  fontWeight: 700,
  fontFamily: "Outfit",
});

const CustomTableContainer = styled(TableBody)({
  backgroundColor: "#FFFFFF",
  "&.isUnitsDisabled": {
    display: "none"
  }
});

const CustomIconButtonUp = styled(Typography)({
  cursor: "pointer",
  marginBottom: "-14px",
});

const CustomIconButtonDown = styled(Typography)({
  cursor: "pointer",
  marginTop: "0px",
});

const StatusTableCell = styled(TableCell)({
  whiteSpace: "nowrap",
  fontSize: "12px",
  fontFamily: "Outfit",
  color: "#000000",
  fontWeight: 400,
  textAlign: "center",
});

const StatusTablesCell = styled(TableCell)({
  fontSize: "12px",
  fontFamily: "Outfit",
  fontWeight: 400,
  textAlign: "center",
  color: "#000000"
});

const ViewCell = styled(TableCell)({
  whiteSpace: "nowrap",
  fontSize: "12px",
  fontFamily: "Outfit",
  color: "#CC9200",
  fontWeight: 400,
  textAlign: "center",
});

const ViewHexIcon = styled("img")({
  right: "0",
  position: "absolute",
  "@media(max-width: 992px)": {
    display: "none"
  }
});

const DialogImg = styled("img")({
  right: 8,
  top: 8,
  position: "absolute",
  cursor: "pointer"
});

const webStyle = {
  previewstyle: {
    fontFamily: "outfit",
    fontSize: "14px",
    fontWeight: 700,
    color: "#CC9200"
  },
  marginManage: {
    '& .MuiOutlinedInput-root': {
      borderRadius: 2,
    }
  },
  marginBorderstyle: {
    borderRadius: "8px",
    marginTop: "2rem"
  },
  labeltextsyle: {
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "22px",
    fontFamily: "Outfit",
    marginBottom: "2rem",
    color: 'rgba(0, 0, 0, 0.6)'
  },
  marginBorderstyle12: {
    marginTop: "2rem",
    borderRadius: "8px"
  },
  textstylechange12: {
    fontFamily: "Outfit",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "22px",
    color: 'rgba(0, 0, 0, 0.6)'
  },
  
  input_label: {
    fontFamily: "Outfit, sans-serif",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "22px",
  },
  modalTitle: {
    fontSize: "14px",
    fontWeight: 700,
    fontFamily: "Outfit, sans-serif",
    lineHeight: "20px",
  },
  nameliststyle: {
    fontSize: "12px",
    fontWeight: 400,
    fontFamily: "Outfit, sans-serif",
    lineHeight: "12px",
    borderBottom: "none",
    color: "#BAB1AB"
  },
  nameliststyle1: {
    fontSize: "12px",
    fontWeight: 400,
    fontFamily: "Outfit, sans-serif",
    lineHeight: "12px",
    borderBottom: "none",
    color: "#000000"
  },
  modalKeyStyle: {
    fontSize: "12px",
    fontWeight: 400,
    fontFamily: "Outfit, sans-serif",
    lineHeight: "12px",
    margin: "8px 0",
    color: "#BAB1AB",
  },
  bordernonestyle: {
    borderBottom: "none",
    color: "#CC9200",
    fontSize: "12px",
    fontWight: 700,
    fontFamily: "Outfit"
  },
  downloadstyle: {
    color: "#CC9200",
    fontSize: "12px",
    fontWight: 700,
    fontFamily: "Outfit",
    borderBottom: "none",
    cursor: "pointer"
  },
  modalValueStyle: {
    fontSize: "12px",
    fontWeight: 400,
    fontFamily: "Outfit, sans-serif",
    lineHeight: "12px",
    margin: "8px 0",
    color: "#000000",
    '&::first-letter': {
      textTransform: 'capitalize',
    },
    "&.isUnitsDisabled": {
      display: "none"
    }
  },
  vendorWorkTitle: {
    fontWeight: 700,
    fontSize: "16px",
    fontFamily: "Outfit"
  },

  chooseFileVendor: {
    fontSize: "14px",
    lineHeight: "22px",
    fontWeight: 700,
    fontFamily: "Outfit",

  },
  clearAllBtn: {
    color: "#CC9200",
    fontSize: "12px",
    fontFamily: "Outfit",
  },
  vendorWorkBtn: {
    borderRadius: "40px",
    color: "#70645C",
    padding: "2px, 8px, 2px, 8px",
    height: "22px",
    fontFamily: "Outfit"
  },
  customChip: {
    alignItems: "center",
    height: "26px",
    display: "flex",
    border: "1px solid black",
    borderRadius: "4px",
    fontFamily: "Outfit",
    padding: "1px 6px 1px 2px",
    gap: "8px"
  },
  fiterToggle: {
    fontSize: "12px",
    fontFamily: "Outfit",
    fontWeight: "700",
  },
  commonStyle: {
    display: "flex",
    alignItems: "center",
    fontFamily: "Outfit",
    justifyContent: "space-between",
  },
  titleStyle: {
    display: "flex",
    fontFamily: "Outfit",
    marginBottom: "1rem",
    alignItems: "center",
  },
  commonStylevendors: {
    fontSize: "12px",
    fontFamily: "Outfit",
    fontWeight: 700,
    lineHeight: "10px",
    color: "#A3978F"
  },
  commonStylevendorsagain: {
    cursor: "pointer",
    fontSize: "12px",
    fontFamily: "Outfit",
    fontWeight: 700,
    lineHeight: "10px",
    color: "#CC9200"
  },
  editColor: {
    color: "#CC9200",
    fontSize: "14px",
    fontFamily: "Outfit",
    fontWeight: 700
  },
  commonStylevendors2: {
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "12px",
    fontFamily: "Outfit",
    color: "#A3978F"
  },
  commonStylevendors1: {
    fontSize: "14px",
    fontFamily: "Outfit",
    lineHeight: "22px",
    fontWeight: 700,
    color: "#A3978F"
  },
  preferedstyle: {
    fontSize: "14px",
    fontWeight: 700,
    fontFamily: "Outfit",
  },
  buttonStyeled: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    fontFamily: "Outfit",
    height: "100%",
  },
  dialogContent: {
    backgroundColor: "#f8fafc",
    fontFamily: "Outfit",
  },
  gridContainer: {
    display: "flex",
    alignItems: "stretch",
    fontFamily: "Outfit",
  },
  gridItem: {
    flex: 1,
    padding: "16px",
    "&:not(:last-child)": {
      borderRight: "1px solid #BAB1AB",
    },
  },
};

const MoreArrow = styled(Box)({
  display: "flex"
});

const CustomDialogContent = styled(DialogContent)({
  background: "#F8FAFC",
   "&.isUnitsDisabled": {
    display: "none"
  }
});

const CustomIconButton = styled(IconButton)({
  marginLeft: "auto",
  color: "#CC9200",
  fontSize: "14px",
  fontFamily: "Outfit",
  fontWeight: 700,
  "&:hover": {
    background: "none"
  }
});

const VendorTableCell = styled(TableCell)({
  whiteSpace: "nowrap",
  fontFamily: "Outfit",
  "&.isUnitsDisabled": {
    display: "none"
  }
});

const CustomChevronRight = styled(ChevronRightIcon)({
  color: "#CC9200"
});

const NavBarArea = styled(Box)({
  position: "fixed",
  height: "100%",
  top: 0,
  overflowY: "auto",
  left: 0,
  zIndex: 1000, 
  border: "1px solid rgb(208,203,199)",
  "@media(max-width: 992px)": {
    position: "unset",
    overflowY: "hidden",
    height: "auto",
    border: "none",
  }
});

const TaskArea = styled(Box)({
  overflowY: "auto",
  marginLeft: "90px", 
  flexGrow: 1,
  height: "100vh",
  "@media(max-width: 992px)": {
    overflowY: "unset",
    marginLeft: "0px", 
    height: "auto",
  }
});

const ViewBox = styled(Box)({
  cursor: "pointer"
});

const AddButton = styled(Button)({
  backgroundColor: "#FFC123",
  color: "#000000",
  width: "fit-content",
  textTransform: "none",
  borderRadius: "8px",
  height: "30px",
  "&:hover": {
    backgroundColor: "#FFC123",
    color: "#000000",
  }
});

const SubmitBtn = styled(Button)({
  width: "175px",
  height: "44px",
  borderRadius: "8px",
  backgroundColor: "#FFC123",
  color: "#000000",
  marginRight: "25px",
  fontWeight: 700,
  cursor: "pointer",
  fontSize: '16px',
  lineHeight: '24px',
  fontFamily: "Outfit, sans-serif",
  "&:hover": {
    backgroundColor: "#FFD700", 
  },
  "@media (max-width:600px)": {
    marginRight: "0px",
  }
});

const CancelButton = styled(Button)({
  width: "175px",
  height: "44px",
  backgroundColor: "#FFF1CC",
  color: "#000000",
  fontWeight: 700,
  cursor: "pointer",
  marginRight: "25px",
  fontSize: '16px',
  lineHeight: '24px',
  borderRadius: "8px",
  fontFamily: "Outfit, sans-serif",
  "&:hover": {
    backgroundColor: "#FFF1CC", 
  },
  "@media (max-width:600px)": {
    marginRight: "0px",
  }
});

const FiltersWeb = styled(Box)({
  width: "59%",
  fontFamily: "Outfit",
  paddingRight: "20px",
  "@media(max-width: 992px)": {
    display: "none"
  }
});

const FiltersMobile = styled(Box)({
  fontFamily: "Outfit",
  display: "none",
  "@media(max-width: 992px)": {
    display: "block",
    width: "93%",
    paddingLeft: "16px"
  }
});

const FilterChips = styled(Box)({
  width: "fit-content",
  borderRadius: "4px",
  display: "flex",
  border: "1px solid #000000",
  fontFamily: "Outfit",
  padding: "1px 6px 1px 2px",
  alignItems: "center",
  gap: "5px",
  height: "fit-content",
  whiteSpace: "nowrap"
});

const FilterDetails = styled(Typography)({
  fontFamily: "Outfit",
  fontSize: "12px",
  color: "#544B45",
  fontWeight: 700,
});

const FilterClose = styled(Close)({
  color: "#544B45",
  fontFamily: "Outfit",
  cursor: "pointer"
});

const FilterContainer = styled(Box)({
  fontFamily: "Outfit",
  width: "100%",
  overflow: "auto",
  display: "flex",
  padding: "10px 10px 10px 10px",
  gap: "1rem"
});

const EditGrid = styled(Grid)({
  "&.isUnitsDisabled": {
    display: "none"
  }
});

const CustomModal = styled(Modal)({
  display: "flex",
  fontFamily: "Outfit",
  alignItems: "center",
  justifyContent: "center",
  padding: "20px"
});

const ParagraphContainer = styled(Box)({
  backgroundColor: "white",
  width: "60%",
  fontFamily: "Outfit",
  padding: "25px 20px 80px 20px",
  height: "380px",
  borderRadius: "8px 8px 30px 8px",
  lineHeight: "2rem",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  gap: "25px",
   "@media(max-width: 1260px)": {
    width: "80%"
  },
  "@media(max-width: 992px)": {
    width: "97%"
  },
  "@media(max-width: 767px)": {
    width: "97%"
  },
  "@media(max-width: 600px)": {
    gap: "15px",
  },
  "@media(max-width: 475px)": {
    gap: "12px",
    height: "400px"
  },
  "@media(max-width: 466px)": {
    gap: "10px"
  }
});

const CloseModal = styled(Box)({
  display: "flex",
  justifyContent: "flex-end",
  cursor: "pointer",
  fontFamily: "Outfit",
});

const KeyContainer = styled(Box)({
  fontFamily: "Outfit",
  display: "flex",
  "@media(max-width: 467px)": {
    flexDirection: "column",
    gap: "10px"
  },
  "& .properties-dropdown": {
    width: "100%",
    fontFamily: "Outfit",
    justifyContent: "space-around",
    backgroundColor: "rgb(253,242,208)",
    textTransform: "none",
    borderRadius: "8px",
    padding: "2px 10px 2px 10px",
    cursor: "pointer",
    color: "#000000",
    display: "flex",
    height: "44px",
    "&:hover": {
      backgroundColor: "rgb(253,242,208)",
      color: "#000000"
    },
    "@media(max-width: 420px)": {
      width: "105px",
      height: "30px",
      fontSize: "12px",
    }
  },
});

const KeyHeading = styled(Button)({
  backgroundColor: "rgb(253,242,208)",
  color: "#000000",
  fontWeight: 700,
  width: "fit-content",
  textTransform: "none",
  fontSize: "20px",
  textAlign: "right",
  fontFamily: "Outfit",
  "&:hover": {
    backgroundColor: "rgb(253,242,208)",
    color: "#000000"
  },
  "@media(max-width: 467px)": {
    fontSize: "16px"
  },
  "@media(max-width: 360px)": {
    fontSize: "15px"
  }
});

const CustomContainer = styled(TableBody)({
  backgroundColor: "#FFFFFF"
});

const DetailsCell = styled(TableCell)({
  color: "#A3978F",
  fontSize: "12px",
  textAlign: "center",
  fontWeight: 700,
  whiteSpace: "nowrap",
});

const StatusCell = styled(TableCell)({
  fontSize: "12px",
  color: "#000000",
  fontWeight: 400,
  whiteSpace: "nowrap",
  textAlign: "center",
  fontFamily: "Outfit"
});

const CustomDialogTitle = styled(DialogTitle)({
  fontWeight: 700,
  fontFamily: "Outfit"
});
// Customizable Area End
