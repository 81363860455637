import React from "react";

// Customizable Area Start
import {
  Container,
  Grid,
  Box,
  Button,
  Typography,
  Avatar,
  Badge,
  List,
  ListItem,
  ListItemButton,
  ListItemAvatar,
  ListItemText,
  TextField,
  IconButton,
  InputAdornment,
  Card,
  CardContent,
  Menu,
  MenuItem,
  Modal,
} from "@mui/material";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import NavigationSidebar from "../../../components/src/NavigationSidebar.web";
export const keasyIcon = require("../../customform/assets/keasylogo.svg");
export const dashboardLandLogo = require("../../customform/assets/dashboard.png");
export const activeMainDashboard = require("../../customform/assets/activeDashboard.png");
export const accountsMain = require("../../customform/assets/profile.png");
export const activeMainAccount = require("../../customform/assets/activeProfile.png");
export const ordersMain = require("../../customform/assets/order.png");
export const activeMainOrder = require("../../customform/assets/activeorder.png");
export const vendorMain = require("../../customform/assets/vendor.png");
export const activeMainVendor = require("../../customform/assets/activeSave.png");
export const remindersMain = require("../../customform/assets/reminder.png");
export const activeMainReminder = require("../../customform/assets/activeReminder.png");
export const propertyMain = require("../../customform/assets/property.png");
export const activePropertyMain = require("../../customform/assets/activeProperty.png");
export const financialMain = require("../../customform/assets/financial.png");
export const activeMainFinancial = require("../../customform/assets/activeFinancial.png");
export const reportMain = require("../../customform/assets/report.png");
export const activeMainReport = require("../../customform/assets/activeReport.png");
export const keyMainChain = require("../../customform/assets/keyChain.png");
export const activeKeyMain = require("../../customform/assets/activeKey.png");
export const settingsMain = require("../../customform/assets/settings.png");
export const activeMainSetting = require("../../customform/assets/activeSetting.png");
export const projects = require("../../customform/assets/projects.png");
export const activeprojects = require("../../customform/assets/activeprojects.png");
export const prefferedVendor = require("../../customform/assets/PrefferedVendors.png");
import {ChatAttachment} from "./ChatController";
import CloseIcon from '@mui/icons-material/Close';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import SendIcon from '@mui/icons-material/Send';
import TagFacesIcon from '@mui/icons-material/TagFaces';
import DehazeIcon from '@mui/icons-material/Dehaze';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  createTheme,
  ThemeProvider,
  Theme,
  StyledEngineProvider,
} from "@mui/material/styles";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
// Customizable Area End

import ChatController, { configJSON, IChat, Props } from "./ChatController";

export default class Chat extends ChatController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  fileInput: HTMLInputElement | null = null;

  renderAddRoomModal = () => {
    return(
      <Modal
        id="add-chat"
        open={this.state.addChatModal}
        onClose={() => this.handleModalClose('chatModal')}
        aria-labelledby="add-chat-modal"
        aria-describedby="add-chat-modal"
      >
        <Box sx={webStyles.modal_style}>
          <Box sx={webStyles.modal_header}>
            <Typography variant="h4" component="h2">
              Create chat
            </Typography>
            <IconButton
              data-test-id="addChatClose"
              onClick={() => this.handleModalClose('chatModal')}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Box sx={webStyles.modal_content}>
          <TextField
            required
            id="name"
            name="name"
            variant="outlined"
            size="small"
            fullWidth
            value={this.state.name}
            error={this.state.errors.name}
            helperText={this.state.errors.name ? "Please enter a valid name": "" }
            onChange={this.handleInputChange}
          />
          <Button
            data-test-id="btnAddAccount"
            size="medium"
            id="nextButton"
            type="submit"
            sx={webStyles.form_button}
            onClick={() => this.addUserToChat()}
          >
            Add
          </Button>
          </Box>
        </Box>
      </Modal>
    )
  };

  attachmentComp(attachment: ChatAttachment) {
    return (
      <Card sx={{ display: 'flex', alignItems: 'center', boxShadow: 2, borderRadius: 2, p: 1 }}>
        <IconButton sx={{ bgcolor: '#fff5e6', borderRadius: 2, mr: 2 }}>
          <DehazeIcon sx={{ color: '#f8bb5e' }} />
        </IconButton>
        <CardContent sx={{ flexGrow: 1 }}>
          <Typography variant="body1">{attachment.file_name}</Typography>
          <Typography variant="body2" color="text.secondary">
            {attachment.size_mb >= 1 ? attachment.size_mb : attachment.size_kb}
          </Typography>
        </CardContent>
        <IconButton id={`attachment-menu-${attachment.id}`} onClick={(menus) => { this.handleMenuClick(menus, attachment.id) }}>
          <MoreVertIcon />
        </IconButton>
        <Menu
          data-test-id="attachment-menu"
          anchorEl={this.state.attachmentAnchorElement[attachment.id]}
          open={Boolean(this.state.attachmentAnchorElement[attachment.id])}
          onClose={() => this.handleMenuClose(attachment.id)}
        >
          <MenuItem id={`menu-item-${attachment.id}`} onClick={()=>this.handleDownload(attachment.file_url, attachment.file_name, attachment.id)}>Download</MenuItem>
        </Menu>
      </Card>
    );
  };

  renderRoomList = () => {
    return (
      <Box sx={{ maxWidth: 400 }}>
        <List>
          <ListItem key="chat-title" sx={{ borderBottom: 1 }}>
            <ListItemText
              primary={
                <Box sx={webStyles.newChat}>
                  <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                    Chat
                  </Typography>
                  <Button data-test-id="btnShowAddModal" variant="contained" sx={webStyles.addChatButton}>
                    <AddCircleOutlineIcon fontSize="medium" />
                  </Button>
                </Box>
              }
            />
            <Button data-test-id="btnShowAddModal" variant="contained" sx={webStyles.addChatButton} onClick={this.showModal}>
              <AddCircleOutlineIcon fontSize="medium" />
            </Button>
          </ListItem>
          {this.state.chatsArray.map((chat) => (
            <ListItemButton
              id={`chatlist-${chat.attributes.id}`}
              onClick={() => this.handleChatClick(chat.attributes.id)} 
              key={chat.attributes.id} 
              sx={{ borderBottom: 1,
                "&.Mui-selected": {
                  backgroundColor: "#FFF1CC",
                },
               }}
              selected={this.state.activeChatId === chat.attributes.id}
            >
              <ListItemAvatar>
                <Avatar alt={chat.attributes.receiver.first_name} src={chat.attributes.receiver.profile_url} />
              </ListItemAvatar>
              <ListItemText
                primary={
                  <Grid container>
                    <Grid item sx={webStyles.chatList} color={chat.attributes.unread_count === 0 ? "#999" : "#DAA520"} xs={10}>
                      <Typography variant="body1" sx={{ 
                        fontWeight: "bold",
                        display: "inline",
                        color: chat.attributes.unread_count === 0 ? "#999" : "#DAA520" 
                      }}>
                        {chat.attributes.receiver.first_name} {chat.attributes.receiver.last_name}
                      </Typography>
                      <Badge sx={{
                          "& .MuiBadge-badge": {
                            backgroundColor: "#DAA520"
                          },
                          marginLeft: "10px"
                        }} variant="dot" invisible={chat.attributes.unread_count === 0}>
                      </Badge>
                      {chat.attributes.receiver.user_type && (
                        <Typography variant="caption" sx={{
                          ml: 1,
                          color: chat.attributes.unread_count === 0 ? "#999" : "#DAA520"
                        }}>
                          {chat.attributes.receiver.user_type}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={1}>
                      <Typography variant="caption" sx={{ color: "#999" }}>
                        {this.timeAgo(chat.attributes.last_message_at)}
                      </Typography>
                    </Grid>
                  </Grid>
                }
                secondary={
                  <Grid container>
                    <Grid item sx={webStyles.chatList} xs={9}>
                      <Typography variant="body2" sx={{ display: "inline", color: "black" }}>
                        {chat.attributes.last_message.message}
                      </Typography>
                    </Grid>
                    <Grid item xs={2}></Grid>
                    <Grid item xs={1}>
                      <Badge sx={{
                          "& .MuiBadge-badge": {
                            backgroundColor: "#FFF1CC",
                            color: "#DAA520"
                          },
                          marginTop: 1,
                          right: "-5px"
                        }}
                        badgeContent={chat.attributes.unread_count}
                        invisible={chat.attributes.unread_count === 0}
                      >
                      </Badge>
                    </Grid>
                  </Grid>
                }
              />
            </ListItemButton>
          ))}
        </List>
      </Box>
    );
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <Grid container direction="row" wrap="nowrap" 
            sx={{ flexDirection: {xs: "column", sm: "column", md: "row"}}}
        >
          <Grid item>
            <NavigationSidebar
                data-test-id={"navId"}
                activeItem={this.state.activeVendorItem}
                sidebarItems={[
                  { label: configJSON.dashboardOrder, labelKey: configJSON.dashboardOrderlabel, icon: dashboardLandLogo, activeIcon: activeMainDashboard, pathName: configJSON.dashboardOrderlabel },
                  { label: configJSON.myAccountOrder, labelKey: configJSON.settingOrderlabel, icon: accountsMain, activeIcon: activeMainAccount, pathName: configJSON.myAccountOrderabel },
                  { label: configJSON.workOrder, labelKey: configJSON.worksOrderlabel, icon: ordersMain, activeIcon: activeMainOrder, pathName: configJSON.worksOrderlabel },
                  { label: configJSON.preferredOrder, labelKey: configJSON.prevendorOrderlabel, icon: vendorMain, activeIcon: activeMainVendor, pathName: configJSON.prevendorOrderlabel },
                  { label: configJSON.remindersOrder, labelKey: configJSON.reminOrderlabel, icon: remindersMain, activeIcon: activeMainReminder, pathName: configJSON.reminOrderlabel },
                  { label: configJSON.mypropOrder, labelKey: configJSON.mypropOrderlabel, icon: propertyMain, activeIcon: activePropertyMain, pathName: configJSON.mypropOrderlabel },
                  { label: configJSON.financiaOrder, labelKey: configJSON.financOrderlabel, icon: financialMain, activeIcon: activeMainReport, pathName: configJSON.financOrderlabel },
                  { label: configJSON.reportOrder, labelKey: configJSON.reportOrderlabel, icon: reportMain, activeIcon: activeMainReport, pathName: configJSON.reportOrderlabel },
                  { label: configJSON.chatOrder, labelKey: configJSON.chatOrderlabel, icon: keyMainChain, activeIcon: activeKeyMain, pathName: configJSON.chatOrderlabel },
                  { label: configJSON.settingOrder, labelKey: configJSON.settings, icon: settingsMain, activeIcon: activeMainSetting, pathName: configJSON.settings }
                ]}
                onClickSidebar={this.handleVendoritemClick}
                keasylogo={keasyIcon}
                openDrawer={this.state.openVendorDrawer}
                onClickDrawer={this.handleVendorDrawer}
            />
          </Grid>
          <Grid item sx={{ flexGrow: 1, borderLeft: "1px solid rgb(208,203,199)", height: "100%" }}>
            <Container
              maxWidth={false}
              sx={{
                padding: {
                  xs: "0 !important", // Remove padding
                }
              }}
            >
              {this.renderAddRoomModal()}
              <Grid container sx={{ height: "100%" }}>
                <Grid item xs={3}>
                  {this.renderRoomList()}
                </Grid>
                <Grid item xs={6}>
                <List sx={{marginTop: "81px"}}>
                    <ListItem key="chat-header" sx={{ borderBottom: 1 }}>
                      <ListItemAvatar>
                        <Avatar alt='profile-picture' src="https://sample-videos.com/img/Sample-png-image-100kb.png" />
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          <Box>
                            <Typography variant="body1" sx={{ fontWeight: "bold", color: "#DAA520" }}>
                            {this.state.activeChatUser.first_name} {this.state.activeChatUser.last_name} | (property and unit)
                            </Typography>
                          </Box>
                        }
                      />
                    </ListItem>
                    <ListItem sx={{ display: "flex", height: "100%", alignItems: "end" }}>
                    <Box width={"100%"}>
                      <List>
                          {this.state.messageList.map((message) => (
                              <React.Fragment key={message.id}>
                                  <ListItem id={`message-${message.id}`} alignItems="flex-start">
                                      <ListItemAvatar>
                                          <Avatar alt={message.attributes.account.first_name} src="https://sample-videos.com/img/Sample-png-image-100kb.png">
                                              {message.attributes.account.first_name.charAt(0)}
                                          </Avatar>
                                      </ListItemAvatar>
                                      <ListItemText
                                          primary={
                                            <Box sx={{ display: "flex" }}>
                                              <Typography
                                                  sx={{ fontWeight: 'bold' }}
                                              >
                                                  {message.attributes.account.first_name}{' '}
                                                  {message.attributes.account.last_name}
                                                  {' |'}
                                              </Typography>
                                              &nbsp;
                                              <Typography>
                                                {message.attributes.account.user_type.charAt(0).toUpperCase() +
                                                      message.attributes.account.user_type.slice(1)}
                                                  (property and unit)
                                              </Typography>
                                            </Box>
                                          }
                                          secondary={
                                              <Typography>
                                                  {message.attributes.message}
                                              </Typography>
                                          }
                                      />
                                  </ListItem>
                                  
                                  {message.attributes.attachments.length > 0 && (
                                    message.attributes.attachments.map((attachment: ChatAttachment) => (
                                      <Box sx={{ marginLeft: '56px', marginBottom: '16px' }} key={attachment.id}>
                                        {this.attachmentComp(attachment)}
                                      </Box>
                                    ))
                                  )}
                              </React.Fragment>
                          ))}
                      </List>

                      <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '16px' }}>
                          <TextField
                            fullWidth 
                            id="messageText"
                            name="messageText"
                            placeholder="Message Keasy"
                            onChange={this.handleInputChange}
                            InputProps={{startAdornment: (
                              <InputAdornment position="start">
                                <IconButton 
                                  data-test-id="btnInsertImage"
                                  sx={{ color: "#DAA520" }}
                                  onClick={() => this.fileInput?.click()}
                                >
                                  <AttachFileIcon />
                                </IconButton>
                                <input
                                  type="file"
                                  data-test-id="fileInput"
                                  accept=".png,.jpeg,.jpg,.mp4,.webm,.ogg,.pdf"
                                  style={{ display: "none" }}
                                  ref={(input) => (this.fileInput = input)}
                                  onChange={(event) => this.handleFileChange(event)}
                                />
                                <IconButton
                                  sx={{ color: "#DAA520" }}
                                >
                                  <TagFacesIcon />
                                </IconButton>
                              </InputAdornment>
                            ), endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  id="btnSendMessage"
                                  sx={webStyles.sendIcon}
                                  onClick={this.handleSendMessage}
                                >
                                  <SendIcon />
                                </IconButton>
                              </InputAdornment>
                            )}}
                          />
                          <IconButton 
                            data-test-id="btnInsert"
                            sx={{ color: "#DAA520" }}
                          >
                            <AttachFileIcon />
                          </IconButton>
                          <IconButton
                            id="btnSend"
                            sx={webStyles.sendIcon}
                          >
                            <SendIcon />
                          </IconButton>
                      </Box>
                  </Box>
                    </ListItem>
                  </List>
                </Grid>
                <Grid item xs={3}>
                  <List sx={{marginTop: "89px", borderLeft: '2px solid #D0D0D0'}}>
                    <ListItem key="profile-header" >
                      <IconButton>
                        <CloseIcon />
                      </IconButton>
                      <ListItemText primary={
                        <Typography sx={{ ml: 2, fontWeight: "bold" }}>
                          Profile
                        </Typography>
                      }/>
                    </ListItem>
                    <ListItem sx={{ p: 0 }}>
                      <Avatar
                        src="https://via.placeholder.com/150" // Replace with actual image URL
                        alt="Mattie Bloomer"
                        sx={{ width: '100%', height: 200, objectFit: 'cover' }}
                        variant="square"
                      />
                    </ListItem>

                    <ListItem sx={{ borderBottom: '2px solid #D0D0D0'}}>
                      <ListItemText primary={
                        <Box sx={{ display: "flex" }}>
                          <Typography variant="h6" fontWeight="bold">
                            {this.state.activeChatUser.first_name} {this.state.activeChatUser.last_name} {' |'}
                          </Typography>
                          &nbsp;
                          <Typography variant="h6">
                            {this.state.activeChatUser.user_type}
                          </Typography>
                        </Box>
                      } />
                    </ListItem>

                    <ListItem sx={{ borderBottom: '2px solid #D0D0D0'}}>
                      <ListItemText primary={
                        <>
                          <Typography variant="body1" color="textSecondary" fontWeight="bold">
                            (property | unit to be added)
                          </Typography>
                          <Typography variant="body1" color="textSecondary">
                            (lease start date to be added)
                          </Typography>
                        </>
                      } />
                    </ListItem>

                    <ListItem>
                      <ListItemText primary={
                        <>
                          <Typography variant="body2" color="textSecondary" fontWeight="bold">
                            Contact:
                          </Typography>
                          <Typography variant="body2" color="textSecondary">
                            Phone number: {this.state.activeChatUser.full_phone_number}
                          </Typography>
                          <Typography variant="body2" color="textSecondary">
                            Email: {this.state.activeChatUser.email}
                          </Typography>
                        </>
                      } />
                    </ListItem>
                  </List>
                </Grid>
              </Grid>
            </Container>
          </Grid>
        </Grid>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyles = {
  sendIcon: {
    backgroundColor: "#FFF1CC",
    color: "black",
    borderRadius: "25%",
  },
  addChatButton: {
    backgroundColor: "#FFF1CC",
    color: "black",
    aspectRatio: '1 / 1',
    minWidth: 'auto',
    padding: 1,
    "&:hover": {
      backgroundColor: "#FFF1CC",
    },
  },
  newChat: {
    display: "flex",
    justifyContent: "space-between"
  },
  chatList: {
    overflow: " hidden",
    textWrap: "noWrap",
    textOverflow: "ellipsis",
  },
  modal_style: {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "10px",
    overflow:'scroll',
    display:'block',
    p: 4
  },

  modal_header: {
    display: "flex",
    justifyContent: "space-between",
    borderBottom: "1px solid #A9A9A9",
    p: 2,
  },

  modal_content: {
    paddingLeft: "10%",
    paddingRight: "10%",
    paddingTop: "5%",
    paddingBottom: "5%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },

  form_button: {
    backgroundColor: "#FFC123",
    fontFamily: "Outfit, sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    textTransform: "capitalize",
    color: "#000000",
    marginTop: "72px",
    height: "44px",
    width: "184px",
    "&:hover": {
      backgroundColor: "#FFC123",
    },
  },
};
// Customizable Area End
