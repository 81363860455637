// Customizable Area Start
import React, { Component } from "react";
import {
  Box,
  Button,
  Typography,
  LinearProgress,
  IconButton,
  FormLabel,
  Grid,
  Alert,Checkbox, FormControlLabel
} from "@mui/material";
import { styled } from "@mui/system";
import { imageCloud } from "../../blocks/portfoliomanagement/src/assets";
import DeleteIcon from "@mui/icons-material/DeleteOutline";
import ImageCollection from "@mui/icons-material/Collections";
// Customizable Area End

// Customizable Area Start
interface FileWithPreview extends File {
  preview: string;
}

const DropzoneContainer = styled(Box)(({ theme }) => ({
  border: "2px dashed #C1C1C1",
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(2),
  textAlign: "center",
  cursor: "pointer",
}));

const FileDetails = styled(Box)(({ theme }) => ({
  alignItems: "center",
  borderRadius: theme.shape.borderRadius,
  border: `1px solid ${theme.palette.divider}`,
  marginTop: theme.spacing(2),
  "& .download-btn": {
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
    gap: "12px"
  }
}));

interface Props {
  label: string;
  name: string;
  files: FileWithPreview[];
  onFilesChange: (name: string, files: FileWithPreview[]) => void;
  onCheckboxChange: (event: React.ChangeEvent<HTMLInputElement>, index: number) => void;
}

interface State {
  files: FileWithPreview[];
  uploadProgress: number;
  error: string | null;
  sharewithtenants: boolean;
  sharewithowners: boolean;
  check: boolean;

  checkboxStates: { [key: number]: { sharewithtenants: boolean; sharewithowners: boolean } };

}
// Customizable Area End

class FileDataUpload extends Component<Props, State> {
  fileInput: HTMLInputElement | null = null;

  state: State = {
    // Customizable Area Start
    files: this.props.files,
    uploadProgress: 100,
    error: null,
    sharewithtenants: false,
    sharewithowners: false,
    check: false,
    checkboxStates: {},
    // Customizable Area End
  };

    // Customizable Area Start
  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevState.files !== this.state.files) {
      this.props.onFilesChange(this.props.name, this.state.files);
    }

    if (prevProps.files !== this.props.files) {
      this.setState({ files: this.props.files });
    }
  }

  componentWillUnmount() {
    this.state.files.forEach((file) => URL.revokeObjectURL(file.preview));
  }

  onDrop = (event: React.DragEvent) => {
    event.preventDefault();
    const filesArray = Array.from(event.dataTransfer.files);
    const acceptedFileTypes = [
      "application/pdf",
      "image/png",
      "image/jpeg",
      "image/jpg",
    ];
    const acceptedFiles: File[] = filesArray.filter((file) =>
      acceptedFileTypes.includes(file.type)
    );
    const fileRejections = filesArray.filter(
      (file) => !acceptedFileTypes.includes(file.type) || file.size > 5242880
    );

    if (fileRejections.length > 0) {
      this.setState({
        error:
          "Invalid file type or one or more files are too large. Maximum size is 5MB.",
      });
    } else {
      this.setState({ error: null });
      const newFiles = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );
      this.setState((prevState) => ({
        files: [...prevState.files, ...newFiles],
      }));
    }
  };

  handleDragOver = (event: React.DragEvent) => {
    event.preventDefault();
  };

  handleRemoveFile = (file: FileWithPreview) => {
    this.setState((prevState) => ({
      files: prevState.files.filter((f) => f !== file),
    }));
    URL.revokeObjectURL(file.preview);
  };

  handleDownload = (file: FileWithPreview) => {
    const link = document.createElement('a');
    link.href = file.preview;
    link.download = file.name;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const { name, checked } = event.target;
    this.setState((prevState) => ({
      checkboxStates: {
        ...prevState.checkboxStates,
        [index]: {
          ...prevState.checkboxStates[index],
          [name]: checked,
        },
      },
    }));
    this.props.onCheckboxChange(event, index);
  };
  

    // Customizable Area End

  render() {
    // Customizable Area Start
    const { label } = this.props;
    const { files, uploadProgress, error } = this.state;

    return (
      <Box sx={{ marginBottom: "16px" }}>
        <FormLabel htmlFor="send" sx={webStyles.input_label}>
          {label}
        </FormLabel>
        <DropzoneContainer
          data-testId="dropzone-attachment"
          onDrop={this.onDrop}
          onDragOver={this.handleDragOver}
          sx={{ mt: 1 }}
        >
          <Box>
            <img src={imageCloud} alt="Cloud Upload" />
            <Typography sx={webStyles.drag_n_drop_text}>
              Drag and Drop files here
            </Typography>
            <Typography sx={webStyles.supported_files_text}>
              Files Supported: PDF, PNG, JPEG
            </Typography>
            <Button
              id="chooseFile"
              variant="contained"
              sx={webStyles.upload_button}
              onClick={() => this.fileInput?.click()}
            >
              Choose File
            </Button>
            <input
              id="fileInput"
              type="file"
              multiple
              accept=".pdf,.png,.jpeg,.jpg"
              style={{ display: "none" }}
              ref={(input) => (this.fileInput = input)}
              onChange={(event) => {
                const selectedFiles = Array.from(event.target.files || []);
                const acceptedFileTypes = [
                  "application/pdf",
                  "image/png",
                  "image/jpeg",
                  "image/jpg",
                ];
                const acceptedFiles: File[] = selectedFiles.filter((file) =>
                  acceptedFileTypes.includes(file.type)
                );
                const fileRejections = selectedFiles.filter(
                  (file) =>
                    !acceptedFileTypes.includes(file.type) ||
                    file.size > 5242880
                );

                if (fileRejections.length > 0) {
                  this.setState({
                    error:
                      "Invalid file type or one or more files are too large. Maximum size is 5MB.",
                  });
                } else {
                  this.setState({ error: null });
                  const newFiles = acceptedFiles.map((file) =>
                    Object.assign(file, {
                      preview: URL.createObjectURL(file),
                    })
                  );
                  this.setState((prevState) => ({
                    files: [...prevState.files, ...newFiles],
                  }));
                }
              }}
            />
            <Typography
              variant="caption"
              display="block"
              sx={webStyles.supported_files_text}
            >
              Maximum size: 5MB
            </Typography>
          </Box>
        </DropzoneContainer>
        {error && (
          <Alert severity="error" sx={{ mt: 2 }}>
            {error}
          </Alert>
        )}
        {files.map((file, index) => (
        <FileDetails key={file.name}>
          <Grid
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "15px",
              flexDirection: {
                xs: "column",
                sm: "row",
              },
              alignItems: {
                xs: "flex-start",
                sm: "center",
              },
            }}
          >
            <Box display={"flex"} alignItems={"center"}>
              <IconButton
                data-testId="delete-btn-icon-attachment"
                id="delete"
                onClick={() => this.handleRemoveFile(file)}
                sx={{ paddingLeft: "0px" }}
              >
                <DeleteIcon sx={webStyles.delete_icon} />
              </IconButton>
              <ImageCollection sx={webStyles.file_icon} />
              <Typography sx={webStyles.file_name}>{file.name}</Typography>
              <Typography sx={webStyles.file_size}>
                {(file.size / 1024 / 1024).toFixed(2)} MB
              </Typography>
            </Box>
            <Box className="download-btn">
            <Button
              id="download"
              variant="text"
              sx={webStyles.download_button}
              onClick={() => this.handleDownload(file)}
            >
              Download
            </Button>
            <Button
              id="preview"
              variant="text"
              sx={webStyles.preview_button}
              onClick={() => window.open(file.preview, "_blank")}
            >
              Preview
            </Button>
            </Box>
          </Grid>
          <Box display="flex" alignItems="center">
            <LinearProgress
              id="linearProgress"
              variant="determinate"
              value={uploadProgress}
              sx={{
                minWidth: "97%",
                mr: 2,
                ml: 1,
                marginTop: "10px",
                "& .MuiLinearProgress-bar": {
                  backgroundColor: "#CC9200",
                },
                backgroundColor: "#E8E5E3",
              }}
            />
          </Box>
          <Grid
            sx={{
              display: "flex",
              flexDirection: "row",
              marginTop: "10px",
              gap: "41px",
              marginLeft: "10px",
            }}
          >
            <Box style={{ display: "flex", alignItems: "center" }}>
              <FormControlLabel
                sx={{
                  ".css-m5f6qo-MuiButtonBase-root-MuiCheckbox-root.Mui-checked, .css-m5f6qo-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate": {
                    color: "#CC9200",
                  },
                }}
                control={
                  <Checkbox
                    name="sharewithtenants"
                    color="primary"
                    checked={this.state.checkboxStates[index]?.sharewithtenants || false} // Use checkbox state for specific file index
                    onChange={(event) => this.handleCheckboxChange(event, index)} // Pass the index to the handler
                  />
                }
                label="Share with tenants"
              />
            </Box>
            <Box style={{ display: "flex", alignItems: "center" }}>
              <FormControlLabel
                sx={{
                  ".css-m5f6qo-MuiButtonBase-root-MuiCheckbox-root.Mui-checked, .css-m5f6qo-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate": {
                    color: "#CC9200",
                  },
                }}
                control={
                  <Checkbox
                    name="sharewithowners"
                    color="primary"
                    checked={this.state.checkboxStates[index]?.sharewithowners || false} // Use checkbox state for specific file index
                    onChange={(event) => this.handleCheckboxChange(event, index)} // Pass the index to the handler
                  />
                }
                label="Share with owners"
              />
            </Box>
          </Grid>
        </FileDetails>
        ))}

      </Box>
    );
    // Customizable Area End
  }
}

const webStyles = {
  input_label: {
    fontFamily: "Outfit, sans-serif",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "22px",
    color: "#544B4B !important",
  },
  upload_button: {
    marginTop: "6px",
    marginBottom: "6px",
    backgroundColor: "#FFF1CC",
    fontFamily: "Outfit, sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    textTransform: "capitalize",
    color: "#000000",
    "&:hover": {
      backgroundColor: "#FFF1CC",
    },
  },
  drag_n_drop_text: {
    fontFamily: "Outfit, sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    color: "#70645C",
  },
  supported_files_text: {
    fontFamily: "Outfit, sans-serif",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    color: "#70645C",
  },

  delete_icon: {
    color: "#CC9200",
    maxHeight: "18px",
  },
  file_icon: {
    color: "#CC9200",
  },
  file_name: {
    fontFamily: "Outfit, sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "18px",
    color: "#000000",
    marginLeft: "11px",
    maxWidth: "10rem",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  file_size: {
    fontFamily: "Outfit, sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "18px",
    color: "#A3978F",
    marginLeft: "19px",
  },
  preview_button: {
    fontFamily: "Outfit, sans-serif",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "22px",
    color: "#CC9200",
    textTransform: "capitalize",
  },
  download_button: {
    fontFamily: "Outfit, sans-serif",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "22px",
    color: "#CC9200",
    textTransform: "capitalize",
    marginLeft: "39%"
  },
};

export default FileDataUpload;
