 // Customizable Area Start
 Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.NewpropertyEndpoint = "account_block/properties"
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "PortfolioManagement";
exports.labelBodyText = "PortfolioManagement Body";
exports.updateVendorEndPoint ="account_block/vendor_accounts";
exports.dashboardLandloard1 = "Dashboard";
exports.myAccountlandloard1 = "My Account";
exports.worklandloard1 = "Work Orders";
exports.preferredlandloard1 = "Preferred Vendors";
exports.reminderslandloard1 = "Reminders";
exports.myproplandloard1 = "My Properties";
exports.financialandloard1 = "Financial";
exports.reportlandloard1 = "Delinquency Report";
exports.chatLandloard1 = "Chat with Keasy";
exports.settingLandlord1 = "Settings";
exports.dashboardLandlabel1 = "landlordashboard";
exports.myAccountlandlabel1 = "landlordaccount";
exports.workOrderslandlabel1 = "landlordworkOrders";
exports.prevendorlandlabel1 = "landlordvendors";
exports.reminlandlabel1 = "landlordreminders";
exports.myproplandlabel1 = "landlordproperties";
exports.reportlandlabel1 = "landlordreport";
exports.chatlandlabel1 = "landlordchat";
exports.settinglandlabel = "Settings2";
exports.dashboardLandlabel = "Dashboard";
exports.workOrderslandlabel = "Appointments";
exports.prevendorlandlabel = "TaskList";
exports.reminlandlabel = "AutomatedPrioritization";
exports.financlandlabel = "Analytics";
exports.reportlandlabel = "VisualAnalytics";
exports.chatlandlabel = "Chat"

exports.btnExampleTitle = "CLICK ME";
exports.backbutton = "Back";
exports.nextbutton = "Next";
exports.newproperty = "  New Property"

exports.btnExampleTitle = "CLICK ME";
exports.backbutton = "Back";
exports.nextbutton = "Next";
exports.newproperty = "  New Property";
exports.getProperty = "account_block/properties";
exports.getPropertySearch = "account_block/properties/search";
exports.postTenant = "account_block/tenants";
exports.getPropertyInfo = "account_block/properties/show_individual_property";
exports.postUnit = "account_block/units";
exports.getVehicleData = "bx_block_categories/categories/vehicle_list";
exports.getPetTypeData = "bx_block_categories/categories/pet_types_list";
exports.financialandloard = "Financial";
exports.financlandlabel = "Analytics";


// Customizable Area End