import React, { Component } from "react";
import {
  Box,
  Button,
  Typography,
  LinearProgress,
  IconButton,
  FormLabel,
  Grid,
  Alert,
} from "@mui/material";
import { styled } from "@mui/system";
import { imageCloud } from "../../blocks/portfoliomanagement/src/assets";
import DeleteIcon from "@mui/icons-material/DeleteOutline";
import ImageCollection from "@mui/icons-material/Collections";

interface FileWithPreview extends File {
  preview: string;
}

const DropzoneContainer = styled(Box)(({ theme }) => ({
  border: "2px dashed #C1C1C1",
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(2),
  textAlign: "center",
  cursor: "pointer",
}));

const FileDetails = styled(Box)(({ theme }) => ({
  alignItems: "center",
  borderRadius: theme.shape.borderRadius,
  border: `1px solid ${theme.palette.divider}`,
  marginTop: theme.spacing(2),
}));

interface Props {
  label: string;
  name: string;
  files: FileWithPreview[];
  onFilesChange: (name: string, files: FileWithPreview[]) => void;
}

interface State {
  files: FileWithPreview[];
  uploadProgress: number;
  error: string | null;
}

class FileUploadData extends Component<Props, State> {
  fileInput: HTMLInputElement | null = null;

  state: State = {
    files: this.props.files,
    uploadProgress: 100,
    error: null,
  };

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevState.files !== this.state.files) {
      this.props.onFilesChange(this.props.name, this.state.files);
    }
  }

  componentWillUnmount() {
    this.state.files.forEach((file) => URL.revokeObjectURL(file.preview));
  }

  onDrop = (event: React.DragEvent) => {
    event.preventDefault();
    const filesArray = Array.from(event.dataTransfer.files);
    const acceptedFileTypes = [
      "application/pdf",
      "image/png",
      "image/jpeg",
      "image/jpg",
    ];
    const acceptedFiles: File[] = filesArray.filter((file) =>
      acceptedFileTypes.includes(file.type)
    );
    const fileRejections = filesArray.filter(
      (file) => !acceptedFileTypes.includes(file.type) || file.size > 5242880
    );

    if (fileRejections.length > 0) {
      this.setState({
        error:
          "Invalid file type or one or more files are too large. Maximum size is 5MB.",
      });
    } else {
      this.setState({ error: null });
      const newFiles = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );
      this.setState((prevState) => ({
        files: [...prevState.files, ...newFiles],
      }));
    }
  };

  handleDragOver = (event: React.DragEvent) => {
    event.preventDefault();
  };

  handleRemoveFile = (file: FileWithPreview) => {
    this.setState((prevState) => ({
      files: prevState.files.filter((f) => f !== file),
    }));
    URL.revokeObjectURL(file.preview);
  };

  handleDownload = (file: FileWithPreview) => {
    const link = document.createElement('a');
    link.href = file.preview;
    link.download = file.name;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  render() {
    const { label } = this.props;
    const { files, uploadProgress, error } = this.state;

    return (
      <Box sx={{ marginBottom: "16px" }}>
        <FormLabel htmlFor="send" sx={webStyles.input_label}>
          {label}
        </FormLabel>
        <DropzoneContainer
          data-testId="dropzone"
          onDrop={this.onDrop}
          onDragOver={this.handleDragOver}
          sx={{ mt: 1 }}
        >
          <Box>
            <img src={imageCloud} alt="Cloud Upload" />
            <Typography sx={webStyles.drag_n_drop_text}>
              Drag and Drop files here
            </Typography>
            <Typography sx={webStyles.supported_files_text}>
              Files Supported: PDF, PNG, JPEG
            </Typography>
            <Button
              id="chooseFile"
              variant="contained"
              sx={webStyles.upload_button}
              onClick={() => this.fileInput?.click()}
            >
              Choose File
            </Button>
            <input
              id="fileInput"
              type="file"
              multiple
              accept=".pdf,.png,.jpeg,.jpg"
              style={{ display: "none" }}
              ref={(input) => (this.fileInput = input)}
              onChange={(event) => {
                const selectedFiles = Array.from(event.target.files || []);
                const acceptedFileTypes = [
                  "application/pdf",
                  "image/png",
                  "image/jpeg",
                  "image/jpg",
                ];
                const acceptedFiles: File[] = selectedFiles.filter((file) =>
                  acceptedFileTypes.includes(file.type)
                );
                const fileRejections = selectedFiles.filter(
                  (file) =>
                    !acceptedFileTypes.includes(file.type) ||
                    file.size > 5242880
                );

                if (fileRejections.length > 0) {
                  this.setState({
                    error:
                      "Invalid file type or one or more files are too large. Maximum size is 5MB.",
                  });
                } else {
                  this.setState({ error: null });
                  const newFiles = acceptedFiles.map((file) =>
                    Object.assign(file, {
                      preview: URL.createObjectURL(file),
                    })
                  );
                  this.setState((prevState) => ({
                    files: [...prevState.files, ...newFiles],
                  }));
                }
              }}
            />
            <Typography
              variant="caption"
              display="block"
              sx={webStyles.supported_files_text}
            >
              Maximum size: 5MB
            </Typography>
          </Box>
        </DropzoneContainer>
        {error && (
          <Alert severity="error" sx={{ mt: 2 }}>
            {error}
          </Alert>
        )}
        {files.map((file) => (
          <FileDetails key={file.name}>
            <Grid
              sx={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: {
                  xs: "column", 
                  sm: "row", 
                },
                alignItems: {
                  xs: "flex-start", 
                  sm: "center",
                },
              }}
            >
              <Box display={"flex"} alignItems={"center"}>
                <IconButton
                  id="delete"
                  onClick={() => this.handleRemoveFile(file)}
                  sx={{ paddingLeft: "0px" }}
                >
                  <DeleteIcon sx={webStyles.delete_icon} />
                </IconButton>
                <ImageCollection sx={webStyles.file_icon} />
                <Typography sx={webStyles.file_name}>{file.name}</Typography>
                <Typography sx={webStyles.file_size}>
                  {(file.size / 1024 / 1024).toFixed(2)} MB
                </Typography>
              </Box>
              <Box>
                <Button
                  id="download"
                  variant="text"
                  sx={webStyles.download_button}
                  onClick={() => this.handleDownload(file)}
                >
                  Download
                </Button>
                <Button
                  id="preview"
                  variant="text"
                  sx={webStyles.preview_button}
                  onClick={() => window.open(file.preview, "_blank")}
                >
                  Preview
                </Button>
              </Box>
            </Grid>
            <Box display="flex" alignItems="center">
              <LinearProgress
                id="linearProgress"
                variant="determinate"
                value={uploadProgress}
                sx={{
                  minWidth: "97%",
                  mr: 2,
                  ml: 1,
                  "& .MuiLinearProgress-bar": {
                    backgroundColor: "#CC9200",
                  },
                  backgroundColor: "#E8E5E3",
                }}
              />
            </Box>
          </FileDetails>
        ))}
      </Box>
    );
  }
}

const webStyles = {
  input_label: {
    fontFamily: "Outfit, sans-serif",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "22px",
    color: "#544B4B !important",
  },
  upload_button: {
    marginTop: "6px",
    marginBottom: "6px",
    backgroundColor: "#FFF1CC",
    fontFamily: "Outfit, sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    textTransform: "capitalize",
    color: "#000000",
    "&:hover": {
      backgroundColor: "#FFF1CC",
    },
  },
  drag_n_drop_text: {
    fontFamily: "Outfit, sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    color: "#70645C",
  },
  supported_files_text: {
    fontFamily: "Outfit, sans-serif",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    color: "#70645C",
  },

  delete_icon: {
    color: "#CC9200",
    maxHeight: "18px",
  },
  file_icon: {
    color: "#CC9200",
  },
  file_name: {
    fontFamily: "Outfit, sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "18px",
    color: "#000000",
    marginLeft: "11px",
    maxWidth: "10rem",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  file_size: {
    fontFamily: "Outfit, sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "18px",
    color: "#A3978F",
    marginLeft: "19px",
  },
  preview_button: {
    fontFamily: "Outfit, sans-serif",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "22px",
    color: "#CC9200",
    textTransform: "capitalize",
  },
  download_button: {
    fontFamily: "Outfit, sans-serif",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "22px",
    color: "#CC9200",
    textTransform: "capitalize",
  },
};

export default FileUploadData;
