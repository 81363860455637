import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import moment, { Moment } from "moment";
import storage from "../../../framework/src/StorageProvider";
import { getStorageData } from "../../../framework/src/Utilities"
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  // Customizable Area Start
  id: string;
  navigation: any;
  // Customizable Area End
}

// Customizable Area Start
//my code
export type TimeSlot = {
  from: string;
  to: string;
  booked_status: boolean;
  sno: string;
};
export interface WorkOrderDetails {
  id: number;
  work_needed: string;
  permission_to_enter: boolean;
  permission_description: null;
  availability: null;
  cost: boolean;
  description: null;
  status: string;
  scheduled_date: null;
  created_at: string;
  updated_at: string;
  created_by: null;
  sub_category: null;
  assigned_vendor: string;
  property: null;
  due_date_date: string;
  due_date_time: string;
  remind_me_date: string;
  remind_me_time: string;
  vendor_account_id: number;
  property_name: string;
  documents: Array<WorkDescriptions>;
  work_order_audits: Array<Updates>;
}

export interface WorkDescriptions {
id: number;
file_name: string;
file_url: string;
}

export interface Updates {
date: string;
updated_by: string;
description: string;
cost: false;
status: string;
}
export interface Property {
name: string;
units: string[];
}

export interface InvalidResponseType {
errors: string;
}

export interface APIPayloadType {
contentType?: string;
method: string;
endPoint: string;
token?: string;
body?: object;
type?: string;
}

export interface Properties {
data: Array<PropertiesDetails>;
}

export interface PropertiesDetails {
  property_id: string
  property_name: string;
  address: string;
  units: Array<Units>;
}

export interface Units {
unit_id: number;
name: string;
status: string;
reminders: [];
future_reminders: [];
}

export interface ApprovedDetails {
message: string;
}

//machine code
export interface Touched {
  description: boolean;
  subCategory: boolean;
  category: boolean;
  title: boolean;
  technician: boolean;
  startDate: boolean;
  frequency: boolean;
  endDate: boolean;
}

export interface Error {
  title: string;
  description: string;
  subCategory: string;
  startDate: string;
  frequency: string;
  category: string;
  endDate: string;
  technician: string;
}

export interface PriorityOrder {
  low: number;
  high: number;
  medium: number;
}

export interface SubTask {
  id: string;
  attributes: {
    status: string;
    priority: string;
    date: string;
  };
}

export interface ResponseJson {
  id: string;
  attributes: {
    title: string;
    description: string;
    service_sub_category_id: number;
    service_category_id: number;
    start_date: string;
    technician: string;
    end_date: string;
    technician_id: number;
    frequency: string;
    task_date_statuses: {
      data: SubTask[];
    };
  };
}

export interface Dropdown {
  label: string;
  value: string;
}

export interface Data {
  id: string;
  title: string;
  status: string;
  categoryName: string;
  priority: string;
  subTaskId: string;
  subCategoryId: string;
  categoryId: string;
  date: string;
  endDate: string;
  technicianId: string;
  description: string;
  subCategoryName: string;
  startDate: string;
  technicianName: string;
  frequency: string;
}

// Customizable Area End

interface S {
  // Customizable Area Start
  //machine code
  subTaskId: string;
  data: Data[];
  taskId: string;
  page: number;
  dataLength: number;
  loading: boolean;
  startDate: string;
  subCategoryList: Dropdown[];
  filterData: Data[];
  openDialogName: string;
  title: string;
  description: string;
  endDate: string;
  rowsPerPage: number;
  technician: string;
  frequency: string;
  category: string;
  subCategory: string;
  categoryList: Dropdown[];
  technicianList: Dropdown[];
//my code
  available_date: Moment;
  start_time: Moment | null;
  id: string | number;
  token: string;
  appointmentsList: TimeSlot[];
  showPicker: boolean;
  activeOrderItem: string;
  openWorkOrder: boolean;
  expanded: number | null;
  selected: {[unitId: number]: number[]};
  workOrderData: Array<WorkOrderDetails>;
  anchorEl: null | HTMLElement;
  selectedProperty: any | null;
  selectedUnit: string | null;
  subMenuAnchorEl: null | HTMLElement;
  properties: PropertiesDetails[];
  selectedMenuItemIndex: null | number;
  approveDetails:  string[];
  openApprove:{[key: number]: boolean };
  selectedApprove: {[unitId: number]: string};
  selecteValueApprove: {[unitId: number]: string};
  anchorElement: { [key: number]: HTMLElement | null };
  showAllItems: boolean;
  itemsToShow: number;
  sortColumn: string;
  sortDirection: "asc" | "desc";
  anchorNewElement: { [key: number]: HTMLElement | null };
  sortState: {
    [key: number]: {
      sortColumn: string;
      sortDirection: "asc" | "desc";
    };
  },
  reloadLoader: boolean;
  messagePopup: boolean;
  messageForPopup: string;
  openFinKey: boolean;
  findKeyDetails: any;
  scheduledDate: Date | null;
  isDatePickerOpen: boolean;
  modifyDate: string;
  messageType: 'success' | 'error' | 'warning' | 'info';
  searchBar: string;
  propertiesName: string;
  chips: { propertyId: number; unitId: number; propertyName: string; unitName: string }[];
  originalProperties: PropertiesDetails[];
  allProperty: [] | null,
  vendorModal: boolean;
  workOrdersId: number,
  openNewVendors: boolean;
  anchorVendorEl: null | HTMLElement;
  vendorsShowAll: any;
  selectedPId: any,
  selectedUId: any,
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: number;
  // Customizable Area End
}

export default class AutomatedprioritisationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  //machine code
  addTaskApiCallId: string = "";
  getTaskListApiCallId: string = "";
  getCategoryListApiCallId: string = "";
  getTechnicianListApiCallId: string = "";
  taskReminderApiCallId: string = "";
  editTaskApiCallId: string = "";
  getSubCategoryListApiCallId: string = "";
  deleteTaskApiCallId: string = "";
  notificationApiCallId: string = "";
  //my code
  getAppointmentsListApiCallId?: string;
  getWorkOrderApiCallId: string = "";
  getPropertiesApiCallId: string = "";
  postApprovedApiCallId: string = "";
  postWaitingApiCallId: string = "";
  postScheduledApiCallId: string = "";
  postVendorApiCallId: string = "";
  getOrigionalApiCallId: string = "";
  getPropertyCallId: string = "";
  getPreferredVendorApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      //machine code
      startDate: "",
      loading: false,
      subTaskId: "",
      filterData: [],
      page: 0,
      dataLength: 0,
      taskId: "",
      rowsPerPage: 5,
      openDialogName: "",
      data: [],
      description: "",
      subCategory: "",
      categoryList: [],
      title: "",
      endDate: "",
      category: "",
      technicianList: [],
      frequency: "",
      subCategoryList: [],
      technician: "",
//my code
      id: 0,
      start_time: moment(new Date()),
      available_date: moment(new Date()),
      appointmentsList: [],
      token: "",
      showPicker: false,
      activeOrderItem: configJSON.reminOrderlabel,
      openWorkOrder: false,
      expanded: null,
      selected: {},
      workOrderData: [],
      anchorEl: null,
      selectedProperty: null,
      selectedUnit: null,
      subMenuAnchorEl: null,
      selectedMenuItemIndex: null,
      properties: [],
      approveDetails: ["Assign a vendor","Complete work", "Decline"],
      openApprove: {},
      selectedApprove: {},
      selecteValueApprove: {},
      anchorElement: {},
      showAllItems: false,
      itemsToShow: 4,
      sortColumn: "id",
      sortDirection: "asc",
      anchorNewElement: {},
      sortState: {},
      reloadLoader: false,
      messagePopup: false,
      messageForPopup: "",
      openFinKey: false,
      findKeyDetails: [],
      scheduledDate: null,
      isDatePickerOpen: false,
      modifyDate: "",
      messageType:  "success",
      searchBar: "",
      propertiesName: "",
      chips: [],
      originalProperties: [],
      allProperty: [],
      vendorModal: false,
      workOrdersId: 0,
      openNewVendors: false,
      anchorVendorEl: null,
      vendorsShowAll: [],
      selectedPId: "",
      selectedUId: "",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    //my code
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      this.setState({ token: token });
      token && this.getAppointmentList(token);
    }

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getAppointmentsListApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson && !responseJson.errors && responseJson.data) {
        this.setState({
          appointmentsList: responseJson.data.attributes.time_slots,
        });
      } else {
        const errorResponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );

        this.parseApiCatchErrorResponse(errorResponse);

        this.setState({ appointmentsList: [] });
      }
    }
    this.handleWorkOrders(message);
    if (this.handlePropertyResponse(message)) {
      return;
    }
    // Customizable Area End
  }

  // Customizable Area Start

  //my code-----------------------------
  getAppointmentList = (token: string) => {
    const header = {
      "Content-Type": configJSON.appointmentApiContentType,
      token: this.state.token,
    };

    const searchParams = {
      service_provider_id: "1",
      availability_date: this.state.available_date.format(
        configJSON.serverFormat
      ),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAppointmentsListApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.appointmentAPiEndPoint}?${new URLSearchParams(searchParams).toString()}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAppointmentListAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  update(value: Partial<{ [K in keyof S]: S[K] }>) {
    this.setState((state) => ({ ...state, ...value, showPicker: false }));
  }

  getDate(value: string, format: string) {
    return moment(value).format(format);
  }

  toMomentDate(value?: string | Date | null, format?: string) {
    return moment(value, format);
  }

  toDateFromMoment(value: Moment) {
    return value.toDate();
  }

  handleSideNav = (navKey: string) => {
    this.setState({ activeOrderItem: navKey }, () => {
      this.handleWorkOrderMenu();
    });
  };

  handleWordDrawer = () => {
    this.setState({
      openWorkOrder: !this.state.openWorkOrder
    });
  };

  handleExpandClick = (rowId: number) => {
    this.setState((prevState) => ({
      expanded: prevState.expanded === rowId ? null : rowId
    }));
  };

  handleSelectClick = (selectedId: number, orderId: number) => {
   this.setState(prevState => {
      const selectedUnit = prevState.selected[selectedId] || [];
      const selectedIndex = selectedUnit.indexOf(orderId);
      let newSelected: number[] = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selectedUnit, orderId);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selectedUnit.slice(1));
      } else if (selectedIndex === selectedUnit.length - 1) {
        newSelected = newSelected.concat(selectedUnit.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selectedUnit.slice(0, selectedIndex),
          selectedUnit.slice(selectedIndex + 1)
        );
      }

      return {
        selected: {
          ...prevState.selected,
          [selectedId]: newSelected
        },
      };
    });
  };

  isSelected = (unitId: number, orderId: number) => {
    const selectedUnit = this.state.selected[unitId] || [];
    return selectedUnit.includes(orderId);
  };

  handleWorkOrderMenu = () => {
    const { activeOrderItem } = this.state;
    const toMsgWork = new Message(getName(MessageEnum.NavigationMessage));
    toMsgWork.addData(
      getName(MessageEnum.NavigationTargetMessage),
      activeOrderItem
    );
    toMsgWork.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(toMsgWork);
  };

  handleMenuClick = (item: string, unitId: number) => {
    this.setState((prevState) => ({
      selectedApprove: {
        ...prevState.selectedApprove,
        [unitId]: item
      },
      selecteValueApprove: {
        ...prevState.selectedApprove,
        [unitId]: this.getStatus(item)
      },
      anchorElement: {
        ...prevState.anchorElement,
        [unitId]: null
      }
    }));
  };

  getStatus = (item: string) => {
    switch (item) {
      case "Assign a vendor":
        return "assigned_to_vendor";
      case "Complete work":
        return "completed";
      case "Decline":
        return "declined";
      default:
        return "";
    }
  };

  handleProperClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({ anchorEl: event.currentTarget }, () => {
      this.getPropertiesDetails("", "");
    });
  };

  handleClose = () => {
    this.setState({
      anchorEl: null,
      subMenuAnchorEl: null,
      selectedProperty: null,
      selectedUnit: null
    });
  };

  handlePropertyClick = (propEvent: React.MouseEvent<HTMLElement>, property: any, indexItem: number) => {
    this.setState({
      selectedProperty: property,
      subMenuAnchorEl: propEvent.currentTarget,
      selectedMenuItemIndex: indexItem,
      propertiesName: property.property_name
    });
  };

  handleUnitClick = (unit: string, unitId: number) => {
    const { selectedProperty, chips } = this.state;

    if (selectedProperty) {
      const newChip = {
        propertyId: parseInt(selectedProperty.property_id),
        unitId: unitId,
        propertyName: selectedProperty.property_name,
        unitName: unit
      };

      const chipExists = chips.some(
        chip => chip.propertyId === newChip.propertyId && chip.unitId === newChip.unitId
      );

      if (!chipExists) {
        this.setState((prevState) => ({
          chips: [...prevState.chips, newChip],
          selectedUnit: unit,
          anchorEl: null,
          subMenuAnchorEl: null,
          selectedProperty: null,
          messagePopup: false
        }), () => {
          this.getPropertiesDetails("", "");
        });
      } else {
        this.setState({
          messageForPopup: configJSON.filteredMessage,
          messageType: configJSON.errorMessage,
          messagePopup: true
        });
      }
    }
  };

  handleCloseMenu = () => {
    this.setState({ subMenuAnchorEl: null });
  };

  handleMenuIconClick = (eventIcon: React.MouseEvent<HTMLElement>, rowId: number) => {
    this.setState({
      anchorNewElement: {
        ...this.state.anchorNewElement,
        [rowId]: eventIcon.currentTarget
      },
    });
  };

  handleMenuClose = (rowId: number) => {
    this.setState({
      anchorNewElement: {
        ...this.state.anchorNewElement,
        [rowId]: null
      },
    });
  };

  async handleWorkOrders(message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (!responseJson.errors && responseJson) {
        this.apiSuccessCallBacks(apiRequestCallId, responseJson);
      } else if (responseJson && responseJson.errors) {
        this.apiFailCallBack(apiRequestCallId, responseJson);
      }
    }
  };

  workOrderApiCall = async (data: APIPayloadType) => {
    let { contentType, method, endPoint, body ,type } = data;
    const header = {
      "Content-Type": contentType,
      token: this.state.token
    };

    let requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    body &&
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      type == "formData" ? body:JSON.stringify(body)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  apiSuccessCallBacks = (apiRequestCallID: string, responseJson: Properties & ApprovedDetails) => {
    if (apiRequestCallID === this.getPropertiesApiCallId) {
      this.setState({
        properties: responseJson.data
      });
    }

    if (apiRequestCallID === this.postApprovedApiCallId) {
      this.setState({
        messagePopup: true,
        messageType: "success",
        messageForPopup: "Status updated successfully for selected Reminder"
      }, () => {
        this.getPropertiesDetails("", "");
      });
    }

    if (apiRequestCallID === this.postWaitingApiCallId) {
      this.setState({
        messagePopup: true,
        messageType: "success",
        messageForPopup: "Status updated to 'Pay Bill' successfully for selected work orders"
      }, () => {
        this.getPropertiesDetails("", "");
      });
    }

    if (apiRequestCallID === this.postScheduledApiCallId) {
      this.setState({
        messagePopup: true,
        messageType: "success",
        messageForPopup: "Scheduled date updated successfully for selected work orders"
      }, () => {
        this.getPropertiesDetails("", "");
      });
    }

    if (apiRequestCallID === this.postVendorApiCallId) {
      this.setState({
        messagePopup: true,
        messageType: "success",
        messageForPopup: "Assignee updated successfully for selected reminder"
      }, () => {
        this.getPropertiesDetails("", "");
      });
    }

    if (apiRequestCallID === this.getOrigionalApiCallId) {
      this.setState({
        originalProperties: responseJson.data
      });
    }
    if (apiRequestCallID === this.getPreferredVendorApiCallId) {
      this.setState({
        vendorsShowAll: responseJson.data
      });
    }

  };
  private handlePropertyResponse(message: Message): boolean {
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getPropertyCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson && !responseJson.errors && responseJson.data) {
        this.setAllProperty(responseJson.data);
      }
      return true;
    }
    return false;
  }
  setAllProperty = (data: any) => {
    this.setState({
      allProperty : data,
    });
  }

  getPropertiesDetails = async (propertiesId: string, searchParams: string) => {
    
    let endPointdata = configJSON.propertiesApiEndPoint;
    const { chips } = this.state;
    const propertyIds = chips.map(chip => chip.propertyId).join(',');
    const unitIds = chips.map(chip => chip.unitId).join(',');

    if (propertyIds || searchParams) {
      endPointdata = `${configJSON.propertiesApiEndPoint}?filter_by=${searchParams}&property_id=${propertyIds}&unit_id=${unitIds}&search=${this.state.searchBar}`;  
    } else if (this.state.searchBar) {
      endPointdata = `${configJSON.propertiesApiEndPoint}?search=${this.state.searchBar}&filter_by=${searchParams}&property_id=${propertyIds}&unit_id=${unitIds}`;
    }
    this.getPropertiesApiCallId = await this.workOrderApiCall({
      contentType: configJSON.appointmentApiContentType,
      method: configJSON.getAppointmentListAPiMethod,
      endPoint: endPointdata
    });
  };

  getAllProperty = async () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getPropertyCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getPropertyAcceptanceApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAppointmentListAPiMethod
    );
   
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  toggleShowAll = () => {
    this.setState(prevState => ({
      showAllItems: !prevState.showAllItems
    }));
  };

  handlePropertySlice = () =>{
    return !this.state.showAllItems && this.state.originalProperties.length > 4;
  };

  handlePropertyData = () =>{
    return this.state.originalProperties.slice(0, this.state.showAllItems ? this.state.originalProperties.length : 4);
  };

  goToProperties = () => {
    const navigationMessage = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    navigationMessage.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "PortfolioManagement"
    );
    navigationMessage.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    runEngine.sendMessage("MergeEngineUtilities", navigationMessage);
  }

  handleSortRequest = (property: string, direction: "asc" | "desc", unitIndex: number) => {
    this.setState(prevState => ({
      sortState: {
        ...prevState.sortState,
        [unitIndex]: {
          sortColumn: property,
          sortDirection: direction
        }
      }
    }));
  };

  sortData = (newData: any, unitIndex: number) => {
    const { sortState } = this.state;
    const { sortColumn, sortDirection } = sortState[unitIndex] || {};
    return [...newData].sort((sortingA, sortingB) => {
      const aValue = sortingA[sortColumn as keyof WorkOrderDetails];
      const bValue = sortingB[sortColumn as keyof WorkOrderDetails];
      if (bValue === null || bValue === undefined) return -1;
        if (typeof aValue === "string" && typeof bValue === "string") {
        return aValue.localeCompare(bValue) * (sortDirection === "asc" ? 1 : -1);
      } else if (typeof aValue === "number" && typeof bValue === "number") {
        return (aValue - bValue) * (sortDirection === "asc" ? 1 : -1);
      } else {
        return 0;
      }
    });
  };

  handleScrollableContainer = (unitDetails: any) => {
    return unitDetails.reminders.length > 3 ? "scrollable-container" : "";
  };

  handleDetailsContainer = (unitDetails: any) => {
    return unitDetails.length > 0 ? "" : "isUnitsDisabled";
  };

  handleEmptyContainer = (unitDetails: any[]) => {
    return unitDetails.length > 0 ? "isUnitsDisabled" : "";
  };

  handleChangeColor = (itemIndex: number) =>{
    return this.state.selectedMenuItemIndex === itemIndex ? "changeColor" : "";
  };

  getApprovedStatusChange = async (formData: any,orderId: any) => {
    this.postApprovedApiCallId = await this.workOrderApiCall({
      method: configJSON.putAppointmentListAPiMethod,
      endPoint: `${configJSON.appointmentAPiEndPoint}/${orderId}`,
      body: formData,
      contentType: 'application/json',
    });
  };

  apiFailCallBack = (apiRequestCallID: string, responseJSON: InvalidResponseType) => {
    if (apiRequestCallID === this.getPropertiesApiCallId) {
      this.setState({
        messagePopup: true,
        messageForPopup: responseJSON.errors,
        messageType: "error"
      });
    }

    if (apiRequestCallID === this.postApprovedApiCallId) {
      this.setState({
        messagePopup: true,
        messageType: "error",
        messageForPopup: "Something went wrong!"
      });
    }

    if (apiRequestCallID === this.postScheduledApiCallId) {
      this.setState({
        messageType: "error",
        messagePopup: true,
        messageForPopup: "Something went wrong!"
      });
    }

    if (apiRequestCallID === this.postVendorApiCallId) {
      this.setState({
        messagePopup: true,
        messageForPopup: "Something went wrong!",
        messageType: "error"
      });
    }

  };

  handlePopupClose = () => {
    this.setState({
      messagePopup: false
    });
  };

  handleModalOpen = (findKey: string) => {
    const findKeyDetails = this.state.allProperty?.filter((property: any) => {
      return  String(property.id) === String(findKey);
    });
    this.setState({
      findKeyDetails: findKeyDetails
    }, () => {
      this.handleModalClose();
    });
  };

  handleModalClose = () => {
    this.setState({
      openFinKey: !this.state.openFinKey
    });
  };

  handleCompleted = (orderId: number, status: string) => {
    const formData = {
      reminder: {
        status: status
      }
    }
    
    this.getApprovedStatusChange(formData, orderId);
    this.handleMenuClose(orderId);
  };

  handleVendorStatus = (orderId: number,property_id: any,unit_id:any) => {
    this.setState({
      vendorModal: true,
      workOrdersId: orderId,
      selectedPId: property_id,
      selectedUId: unit_id,
    }, () => {
      this.handleMenuClose(orderId);
    });
  };

  handleCloseVendor = () => {
    this.setState({
      vendorModal: false
    });
  };
  handleCloseVendors = () => {
    this.setState({
      openNewVendors: false
    }, () => {
      this.handleCloseVendor();
    });
  };
  handleVendorClick = (vendorEvent: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({
      anchorVendorEl: vendorEvent.currentTarget,
      openNewVendors: true
    }, () => {
      this.getPreferredVendors();
    });
  };
  getPreferredVendors = async () => {
    this.getPreferredVendorApiCallId = await this.workOrderApiCall({
      contentType: configJSON.appointmentApiContentType,
      method: configJSON.getAppointmentListAPiMethod,
      endPoint: `${configJSON.getTanentAcceptanceApiEndPoint}?property_id=${this.state.selectedPId}&unit_id=${this.state.selectedUId}`
    });
  };
  handleSelectedVendor = (vendorId: number) => {
    const { workOrdersId } = this.state;
    if (vendorId !== null) {
      const formData = {
        reminder: {
          assigned_to: vendorId
        }
      }
      this.postTransferToVendorStatus(formData, workOrdersId);
      this.handleCloseVendors();
    } else {
      this.setState({
        messageType: "error",
        messagePopup: true,
        messageForPopup: "Vendor profile Id is not available!"
      });
    }
  };
  postTransferToVendorStatus = async (formData: any, orderId: number) => {
    this.postVendorApiCallId = await this.workOrderApiCall({
      method: configJSON.putAppointmentListAPiMethod,
      endPoint: `${configJSON.appointmentAPiEndPoint}/${orderId}`,
      body: formData,
      contentType: 'application/json',
    });
  };

  priorityStyleHandler = (status: string) => {
    let styleObjPriority = {
      not_Started: {
        color: "#70645C",
        background: "#E8E5E3",
        borderColor: "#E8E5E3"
      },
      in_progress: {
        color: "#059669",
        background: "#D1FAE5",
        borderColor: "#D1FAE5"
      },
      review: {
        color: "#059669",
        background: "#D1FAE5",
        borderColor: "#D1FAE5"
      },
      upcoming: {
        color: "#059669",
        background: "#D1FAE5",
        borderColor: "#D1FAE5"
      },
      rejected: {
        color: "#DC2626",
        background: "#FEE2E2",
        borderColor: "#FEE2E2"
      },
      deferred: {
        color: "#DC2626",
        background: "#FEE2E2",
        borderColor: "#FEE2E2"
      },
      contact_tenant: {
        color: "#DC2626",
        background: "#FEE2E2",
        borderColor: "#FEE2E2"
      },
      requires_action: {
        color: "#DC2626",
        background: "#FEE2E2",
        borderColor: "#FEE2E2"
      },
      overdue: {
        background: "#FEF3C7",
        borderColor: "#FEF3C7",
        color: "#D97706"
      },
      completed: {
        color: "#059669",
        background: "#D1FAE5",
        borderColor: "#D1FAE5"
      },
    }
  
    switch (status) {
      case "Not Started":
        return styleObjPriority.not_Started;
      case "In Progress":
        return styleObjPriority.in_progress;
      case "Review":
        return styleObjPriority.review;
      case "Upcoming":
        return styleObjPriority.upcoming;
      case "Rejected":
        return styleObjPriority.rejected;
      case "Deferred":
        return styleObjPriority.deferred;
      case "Contact Tenant":
        return styleObjPriority.contact_tenant;
      case "Requires Action":
        return styleObjPriority.requires_action;
      case "Overdue":
        return styleObjPriority.overdue;
      case "Completed":
        return styleObjPriority.completed;
      default:
        return styleObjPriority.review;
    }
  };
  

  handleProperty = (properties: PropertiesDetails[]) => {
    return properties.length > 0 ? "" : "isUnitsDisabled";
  };
  
  handleEmptyProperty = (properties: PropertiesDetails[]) => {
    return properties.length > 0 ? "isUnitsDisabled" : "";
  };

  handleOpenWork = async(openWorkId: number, openKey: string) => {
    await storage.set("openWorkId",openWorkId)
    const toOpenWork = new Message(getName(MessageEnum.NavigationMessage));
    toOpenWork.addData(
      getName(MessageEnum.NavigationTargetMessage),
      openKey
    );
    toOpenWork.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(toOpenWork);
  };

  handleSearchBar = (searchEvent: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    let searchBar = searchEvent.target.value;
    this.setState({
      searchBar: searchBar
    }, () => {
      this.getPropertiesDetails("", "")
    });
  };

  removeChip = (index: number) => {
    this.setState((prevState) => {
      const chips = [...prevState.chips];
      chips.splice(index, 1);
      return { chips };
    }, () => {
      this.getPropertiesDetails("", "");
    });
  };

  getOrigionalPropertiesDetails = async () => {
    this.getOrigionalApiCallId = await this.workOrderApiCall({
      contentType: configJSON.appointmentApiContentType,
      method: configJSON.getAppointmentListAPiMethod,
      endPoint: configJSON.propertiesApiEndPoint
    });
  };

  clearChips = () => {
    this.setState({
      chips: []
    }, () => {
      this.getPropertiesDetails("", "");
    });
  };

  navigateToCalender() {
    const navigationMessage = new Message(getName(MessageEnum.NavigationMessage));
    navigationMessage.addData(getName(MessageEnum.NavigationTargetMessage), "AutomaticReminder");
    navigationMessage.addData(
      getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigationMessage);
  }

  goToNewReminderScreen = async (propertyId: number,unitId: number) => {
    this.props.navigation.navigate("NewReminders", { propertyId, unitId });
  }

  async componentDidMount() {
    const response = await getStorageData("authToken")
    if (response) {
      this.setState({ token: response }, () => {
      })
    }
    this.getPropertiesDetails("","");
    this.getOrigionalPropertiesDetails();
    this.getAllProperty();
  }


  //machine code----------------------------

  onDelete = (taskId: string, subTaskId: string) => {
    this.setState({ taskId, subTaskId, openDialogName: "Delete" });
  };

  onValueChange = (name: string, value: string) => {
    this.setState({ ...this.state, [name]: value });
  };

  onSubmit = () => {
    this.state.openDialogName == "Add" ? this.onAddTask() : this.onEditTask();
  };

  onChangeHandler = (page: number) => {
    let { rowsPerPage, filterData } = this.state;
    let temp = filterData;
    const length = temp.length;
    const totalPage = Math.ceil(length / this.state.rowsPerPage);
    page = totalPage === page ? page - 1 : page;
    temp = temp.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    this.setState({ data: temp, dataLength: length, page, loading: false });
  };

  onAddBtnClicked = () => {
    this.getCategoryList();
    this.getTechnicianList();
    this.setState({ openDialogName: "Add" });
  };

  onEditBtnClicked = (item: Data) => {
    this.getCategoryList();
    this.getSubCategoryListApi(item.categoryId);
    this.getTechnicianList();
    this.setState({
      description: item.description,
      category: item.categoryId,
      technician: item.technicianId,
      taskId: item.id,
      subCategory: item.subCategoryId,
      startDate: item.startDate,
      title: item.title,
      frequency: item.frequency,
      openDialogName: "Edit",
      endDate: item.endDate,
    });
  };

  handleLogout = () => {
    // localStorage.clear();
    this.props.navigation.navigate("LoginNew");
  };

  onCancel = () => {
    this.setState({
      endDate: "",
      description: "",
      technician: "",
      category: "",
      subCategory: "",
      taskId: "",
      subCategoryList: [],
      openDialogName: "",
      frequency: "",
      title: "",
      startDate: "",
    });
  };

  notificationSend = (id: string) => {
    const header = {
      "content-type": configJSON.notificationApiContentType,
      token: "", // localStorage.getItem("accessToken"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.notificationApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.notificationApiEndPoint}/${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.notificationApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getCategoryList = () => {
    this.setState({ loading: true });

    const header = {
      "Content-Type": configJSON.getCategoryListApiContentType,
      token: "", // localStorage.getItem("accessToken"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCategoryListApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getCategoryListApiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCategoryListApiEndPoint
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getTaskList = () => {
    this.setState({ loading: true });

    const header = {
      "Content-Type": configJSON.getTaskListApiContentType,
      token: "", // localStorage.getItem("accessToken"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getTaskListApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getTaskListApiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getTaskListApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getTechnicianList = () => {
    this.setState({ loading: true });

    const header = {
      "Content-Type": configJSON.getTechnicianListApiContentType,
      token: "", // localStorage.getItem("accessToken"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getTechnicianListApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getTechnicianListApiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getTechnicianListApiEndPoint
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getSubCategoryListApi = (categoryId: string) => {
    this.setState({ loading: true, subCategory: "" });

    const header = {
      "Content-Type": configJSON.getSubCategoryListApiContentType,
      token: "", // localStorage.getItem("accessToken"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSubCategoryListApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getSubCategoryListApiEndPoint}/${categoryId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getSubCategoryListApiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  onEditTask = () => {
    this.setState({ loading: true });

    const header = {
      "Content-Type": configJSON.editTaskApiContentType,
      token: "", // localStorage.getItem("accessToken"),
    };

    const httpBody = {
      data: {
        attributes: {
          title: this.state.title,
          service_sub_category_id: this.state.subCategory,
          technician_id: this.state.technician,
          start_date: moment(this.state.startDate).format("DD/MM/yyyy"),
          description: this.state.description,
          frequency: this.state.frequency,
          service_category_id: this.state.category,
          end_date: moment(this.state.endDate).format("DD/MM/yyyy"),
        },
      },
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.editTaskApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.editTaskApiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.editTaskApiEndPoint}/${this.state.taskId}`
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  onAddTask = () => {
    this.setState({ loading: true });

    const header = {
      "Content-Type": configJSON.addTaskApiContentType,
      token: "", // localStorage.getItem("accessToken"),
    };

    const httpBody = {
      data: {
        attributes: {
          start_date: moment(this.state.startDate).format("DD/MM/yyyy"),
          service_category_id: this.state.category,
          service_sub_category_id: this.state.subCategory,
          frequency: this.state.frequency,
          technician_id: this.state.technician,
          description: this.state.description,
          title: this.state.title,
          end_date: moment(this.state.endDate).format("DD/MM/yyyy"),
        },
      },
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.addTaskApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addTaskApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.addTaskApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  onDeleteTask = () => {
    this.setState({ loading: true });

    const header = {
      "Content-Type": configJSON.deleteTaskApiContentType,
      token: "", // localStorage.getItem("accessToken"),
    };

    const httpBody = {
      data: {
        attributes: {
          task_date_statuses_attributes: [
            {
              _destroy: true,
              id: this.state.subTaskId,
            },
          ],
        },
      },
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteTaskApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteTaskApiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.deleteTaskApiEndPoint}/${this.state.taskId}`
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  // Customizable Area End
}
