import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start
export interface IChat {
  id: string;
  muted: boolean;
  unreadCount: number;
  lastMessage: string;
  name: string;
}
interface IChatResponse {
  id: string;
  attributes: {
    name: string;
    accounts_chats: [
      {
        id: string;
        attributes: {
          account_id: number;
          muted: boolean;
          unread_count: number;
        };
      }
    ];
    messages: {
      id: string;
      attributes: { id: number; message: string };
      message: string;
    };
  };
}

interface AccountUser {
  id: number,
  first_name: string,
  last_name: string | null,
  full_phone_number: string,
  email: string,
  user_type: string
};

export interface ChatAttachment {
  id: number,
  file_name: string,
  file_url: string,
  file_type: string,
  size_kb: number,
  size_mb: number
}

interface ChatMessage {
  id: string;
  type: string;
  attributes: {
      id: number;
      message: string;
      account_id: number;
      chat_id: number;
      created_at: string;
      updated_at: string;
      is_mark_read: boolean;
      account: AccountUser;
      attachments: ChatAttachment[];
  };
}
// Customizable Area End
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  accountId: number;
  chatName: string;
  chatList: IChat[];
  isVisibleModal: boolean;
  activeVendorItem: string;
  openVendorDrawer: boolean;
  messageOpen: boolean;
  messageText: string;
  messageAttachment: File[];
  chatsArray: any[];
  activeChatId: number | null;
  activeChatUser: AccountUser;
  messageList: ChatMessage[];
  attachmentAnchorElement: { [key: number]: HTMLElement | null };
  addChatModal: boolean;
  name: string;
  errors: {
    name: boolean
  }
  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class ChatController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getChatListApiCallId: string = "";
  createChatRoomApiCallId: string = "";
  getChatsApiCallId: string = "";
  getChatMessagesApiCallId: string = "";
  addUserToChatApiCallId: string = "";
  sendMessageApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      accountId: -1,
      chatName: "",
      chatList: [],
      isVisibleModal: false,
      activeVendorItem: "Chat",
      openVendorDrawer: false,
      messageOpen: false,
      messageText: "",
      messageAttachment: [],
      chatsArray: [],
      activeChatId: null,
      activeChatUser: {
        id: 0,
        first_name: "",
        last_name: null,
        full_phone_number: "",
        email: "",
        user_type: ""
      },
      messageList: [],
      attachmentAnchorElement: {},
      addChatModal: false,
      name: "",
      errors: {
        name: false,
      },
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    this.getChats();
    if (!this.isPlatformWeb()) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
  }

  getToken = () => {
    const message: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(message);
  };

  isStringNullOrBlank = (string: string) => {
    return string === undefined || string === null || string.length === 0;
  };

  showModal = () => {
    this.setState({ isVisibleModal: true, addChatModal: true });
  };

  hideModal = () => {
    this.setState({ isVisibleModal: false, addChatModal: false });
  };

  navigateToChatView = (chatId: string) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "ViewChat");

    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), {
      chatId: chatId,
    });
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);

    this.send(message);
  };

  getChatList = async (token: string) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getChatListApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getMyChatsApiEndpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  createChatRoom = (chatName: string) => {
    if (this.isStringNullOrBlank(chatName)) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory,
        ""
      );
    } else {
      const header = {
        "Content-Type": configJSON.apiContentType,
        token: this.state.token,
        "Access-Control-Allow-Origin": "*",
      };
      const bodyData = {
        name: chatName,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.createChatRoomApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.createChatRoomApiEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(bodyData)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.postApiMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  };

  inputRoomNameProps = {
    onChangeText: (chatName: string) => {
      this.setState({ chatName });
    },
  };

  btnAddRoomProps = {
    onPress: () => this.createChatRoom(this.state.chatName),
  };

  btnCloseModalProps = {
    onPress: () => this.hideModal(),
  };

  btnShowAddModalProps = {
    onPress: () => {
      this.showModal();
    },
  };

  handleChatNameChange = (chatName: string) => {
    this.setState({ chatName });
  };

  async receive(from: string, message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const errorResponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
    if (errorResponse) this.parseApiCatchErrorResponse(errorResponse);
    if (responseJson?.errors) this.parseApiErrorResponse(responseJson);

    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      const token: string = message.getData(
        getName(MessageEnum.SessionResponseToken)
      );
      runEngine.debugLog("TOKEN", token);
      if (token) {
        this.setState({ token }, () => this.getChatList(token));
      }
    }
    if (
      responseJson?.data &&
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getChatListApiCallId.length > 0 &&
      apiRequestCallId === this.getChatListApiCallId
    ) {
      this.getChatListApiCallId = "";
      const chatList = responseJson.data;
      const results = chatList.map((item: IChatResponse) => {
        const findAccountMuteResult = item.attributes.accounts_chats.find(
          (item) => item.attributes.account_id === this.state.accountId
        )?.attributes.muted;
        return {
          id: item.id,
          name: item.attributes.name,
          muted:
            findAccountMuteResult ??
            item.attributes.accounts_chats[0].attributes.muted,
          unreadCount:
            item.attributes.accounts_chats[0].attributes.unread_count,
          lastMessage: item.attributes.messages?.attributes?.message,
        };
      });
      this.setState({
        chatList: results,
      });
    }
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.createChatRoomApiCallId.length > 0 &&
      apiRequestCallId === this.createChatRoomApiCallId &&
      responseJson
    ) {
      this.createChatRoomApiCallId = "";
      this.hideModal();
      this.getChatList(this.state.token);
    }
    if (apiRequestCallId === this.sendMessageApiCallId) {
      this.getChats();
      this.getChatMessages();
    }
    if (apiRequestCallId === this.addUserToChatApiCallId) {
      this.hideModal();
      this.getChats();
      this.getChatMessages();
    }
    this.handleGetChatListResponse(message);
    this.handleGetChatMessagesResponse(message);
  };

  getChats = () => {
    const header = {
      token: localStorage.getItem("authToken"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getChatsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getChatListApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getChatMessages = () => {
    const header = {
      token: localStorage.getItem("authToken"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getChatMessagesApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getChatListApiEndPoint+this.state.activeChatId+"/messages"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  addUserToChat = () => {
    this.validateName(this.state.name);
    if (!this.isStringNullOrBlank(this.state.name)) {
      const header = {
        token: localStorage.getItem("authToken"),
      };
  
      const formData = new FormData();
  
      formData.append(
        "chat[name]",
        this.state.name
      );
  
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      this.addUserToChatApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getChatListApiEndPoint
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formData
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.postApiMethod
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
  };

  sendChatMessage = async () => {
    const header = {
      token: localStorage.getItem("authToken"),
    };

    const formData = new FormData();

    formData.append(
      "message[message]",
      this.state.messageText
    );

    if(this.state.messageAttachment.length) {
      this.state.messageAttachment.forEach((file) => {
        formData.append("message[attachments][]", file as Blob);
      });
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.sendMessageApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getChatListApiEndPoint+this.state.activeChatId+"/messages"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleGetChatListResponse = (message:Message) => {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    
    if (apiRequestCallId === this.getChatsApiCallId && responseJson?.data?.length) {
      this.setState({chatsArray: responseJson?.data});
    }
    else {
      if (responseJson?.errors && responseJson?.errors[0].token) {
        this.setState({
          messageOpen: true,
          messageText: "Session Expired, Please Log in again."
        });
      } else {
        this.setState({
          messageOpen: true,
          messageText: responseJson?.errors
        });
      }
    }
  };

  handleGetChatMessagesResponse = (message:Message) => {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    
    if (apiRequestCallId === this.getChatMessagesApiCallId && responseJson?.data?.length) {
      this.setState({messageList: responseJson?.data});
    }
    else {
      if (responseJson?.errors && responseJson?.errors[0].token) {
        this.setState({
          messageOpen: true,
          messageText: "Session Expired, Please Log in again."
        });
      } else {
        this.setState({
          messageOpen: true,
          messageText: responseJson?.errors
        });
      }
    }
  };

  timeAgo = (timestamp: string) => {
    const now: number = new Date().getTime();
    const lastMessageTime: number = new Date(timestamp).getTime();

    if (isNaN(lastMessageTime)) {
      throw new Error("Invalid timestamp provided.");
    }

    const differenceInMs: number = now - lastMessageTime;
  
    const seconds: number = Math.floor(differenceInMs / 1000);
    const minutes: number = Math.floor(seconds / 60);
    const hours: number = Math.floor(minutes / 60);
    const days: number = Math.floor(hours / 24);

    if (days > 0) {
      return `${days}d`;
    } else if (hours > 0) {
      return `${hours}h`;
    } else if (minutes > 0) {
      return `${minutes}m`;
    } else {
      return `Now`;
    }
  };

  handleVendoritemClick = (vendorKey: string) => {
    this.setState({ activeVendorItem: vendorKey });
  };

  handleVendorDrawer = () => {
      this.setState({
          openVendorDrawer: !this.state.openVendorDrawer
      });
  };

  handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    } as unknown as Pick<S, keyof S>);
    if (name === "name") {
      this.validateName(value);
    }
  };

  handleFileChange = (event: any) => {
    const selectedFiles: File[] = Array.from(event.target.files || []);
    
    this.setState((prevState) => ({
      messageAttachment: [...prevState.messageAttachment, ...selectedFiles],
    }));
  };

  handleSendMessage = () => {
    this.sendChatMessage();
    this.setState({ messageText: "", messageAttachment: [] });
  };

  handleChatClick = (id: number) => {
    const chat = this.state.chatsArray.find(chat => chat.attributes.id === id);
    const user = chat.attributes.receiver;
    this.setState({ activeChatId: id, activeChatUser: user }, () => {
      this.getChatMessages();
    });
  };

  handleMenuClick = (eventIcon: React.MouseEvent<HTMLElement>, attachmentId: number) => {
    this.setState({
      attachmentAnchorElement: {
        ...this.state.attachmentAnchorElement,
        [attachmentId]: eventIcon.currentTarget
      },
    });
  };

  handleMenuClose = (attachmentId: number) => {
    this.setState({
      attachmentAnchorElement: {
        ...this.state.attachmentAnchorElement,
        [attachmentId]: null
      },
    });
  };

  handleDownload = (downloadUrl: string, filename: string, attachmentId: number) => {
    const link = document.createElement('a');
    link.href = downloadUrl;

    // Set the download attribute to the desired file name
    link.download = filename;

    // Append the anchor to the body
    document.body.appendChild(link);

    // Trigger the download by simulating a click
    link.click();

    // Remove the anchor from the document
    document.body.removeChild(link);
    this.handleMenuClose(attachmentId);
  };

  handleModalClose = (type: string) => {
    if(type === 'chatModal') {
      this.setState({ addChatModal: false });
    }
  };

  validateName = (name: string) => {
    const regex = /^[a-zA-Z]+(?: [a-zA-Z]+)* ?$/;
    if (!regex.test(name)) {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          name: true,
        },
      }));
    } else {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          name: false,
        },
      }));
    }
  };
  // Customizable Area End
}
