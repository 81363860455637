import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import JsFileDownloader from "js-file-downloader";
import { getStorageData } from "../../../../packages/framework/src/Utilities";
import { AlertColor } from "@mui/lab";
interface WorkOrder {
  id: number;
  work_needed: string;
  description: string;
  status: string;
  requested_by: string;
}

interface InvoiceDetail {
  id: number;
  invoice_date: string;
  due_date: string;
  price: string;
  taxes: string;
  property_name: string;
  work_order: WorkOrder;
}

interface InvoiceAttributes {
  invoice_detail: InvoiceDetail;
  invoice_pdf_url: string;
}

interface Invoice {
  id: string;
  type: string;
  attributes: InvoiceAttributes;
}
// Customizable Area End

export const configJSON = require("./config");



export interface Props {
  // Customizable Area Start
  navigation: object;
  id: string;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  loading: boolean;
  invoice: string;
  invoiceError: string;
  invoicePdf: string;
  activeVendorItem: string;
  openVendorDrawer: boolean;
  startDate: Date | null;
  endDate: Date | null;
  startDateOpen: boolean;
  endDateOpen: boolean;
  searchInput: string;
  messageOpen: boolean;
  messageType: AlertColor;
  message: string;
  invoices: Invoice[];
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: string | number;
  // Customizable Area End
}

export default class InvoiceBillingController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  pdfApiCallId: string | Message = "";
  invoiceApiCallId: string | Message = ""; 
  invoiceSearchApiCallId: string|Message="";

  async componentDidMount() { 
    this.getInvoicelist();
  }
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area Start
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      loading: false,
      invoice: "",
      invoiceError: "",
      invoicePdf: "",
      activeVendorItem: "InvoiceBilling",
      openVendorDrawer: false,
      startDateOpen:false,
      endDateOpen:false,
      startDate:null,
      endDate:null,
      searchInput: "",
      invoices:[],
      messageOpen: false,
      messageType: "success",
      message: "",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    let errorResponse1 = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
    if (apiRequestCallId === this.invoiceApiCallId) {
      if (!responseJson.errors && responseJson) {
        this.setState({
          invoices: responseJson.data, 
        });
      } else if (responseJson && responseJson.errors) {
        this.setState({
          messageOpen: true,
          messageType: "error",
          message: responseJson.errors
        });
      }
      };
      if (apiRequestCallId === this.invoiceSearchApiCallId) {
        if (!responseJson.errors && responseJson) {
          this.setState({
            invoices: responseJson.data, 
          });
        } else if (responseJson && responseJson.errors) {
          this.setState({
            messageOpen: true,
            messageType: "error",
            message: responseJson.errors
          });
        }
        };
      
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.pdfApiCallId !== null &&
      this.pdfApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
    
      if (responseJson && responseJson.invoice) {
        this.setState({
          invoicePdf: responseJson.invoice,
          loading: false,
        });
        window.open(responseJson.invoice);
      } else {
        this.setState({
          loading: false,
        });
        this.parseApiErrorResponse(responseJson);
      }
      this.parseApiCatchErrorResponse(errorResponse1);
    }
    // Customizable Area End
  }

  // Customizable Area Start

      onChangeInvoice = (text: string) => {
          if (text === "") {
            this.setState({
              invoice: text,
              invoiceError: configJSON.invoiceValidation,
            });
          } else {
            this.setState({ invoice: text, invoiceError: "" });
          }

        };

       
        handleVendoritemClick = (vendorKey: string) => {
          this.setState({ activeVendorItem: vendorKey });
        };

        handleVendorDrawer = () => {
            this.setState({
                openVendorDrawer: !this.state.openVendorDrawer
            });
        };
        handleFilter = async() => {
          console.log("thisssss",this.state.startDate,this.state.endDate);
          const { startDate, endDate, searchInput } = this.state;
          const formatDate = (date:Date) => {
            if (!date) return null;
            const d = new Date(date);
            const day = ('0' + d.getDate()).slice(-2);
            const month = ('0' + (d.getMonth() + 1)).slice(-2);
            const year = d.getFullYear();
            return `${day}-${month}-${year}`;
          };
        
          // Format startDate and endDate
          const formattedStartDate = startDate ? formatDate(startDate) : null;
          const formattedEndDate = endDate ? formatDate(endDate) : null;
          const searchUrl = `${configJSON.getInvoiceListAPIEndPoint}?property_name=${searchInput || ''}&start_date=${formattedStartDate || ''}&end_date=${formattedEndDate || ''}`;
          const token = await getStorageData("authToken")
          const header = {
            token: token,
          };
          const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
          );
      
          this.invoiceSearchApiCallId = requestMessage.messageId;
      
          requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            searchUrl
          );
          requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
          );
          requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.exampleAPiMethod
          );
          runEngine.sendMessage(requestMessage.id, requestMessage);
        };
      handleOpen = (field: string) => {
        if (field === "startDate") {
            this.setState({ startDateOpen: true, endDateOpen: false });
        } else if (field === "endDate") {
            this.setState({ startDateOpen: false, endDateOpen: true });
        }
    };
    handleDateChange =(newDate: Date | null,field:string)=>{
      if(newDate){
        if(field==="startDate"){
        this.setState({ startDate: newDate });
        }else{
        this.setState({ endDate: newDate });
        }
      }
    }
    handleInputChange = (
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
     this.setState({
      searchInput:event.target.value
     })
    };
    handleSortRequest = (property: string, direction: "asc" | "desc", unitIndex: number) => {
    
    };
     getInvoicelist = async () => {
    const token = await getStorageData("authToken")

    const header = {
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.invoiceApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getInvoiceListAPIEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
   };
   handleDownload = async (pdfUrl: string) => { 
    try {
      await new JsFileDownloader({
        url: pdfUrl,
      });
    } catch (error) {
      alert("Failed to download file");
    }
  };  
  // Customizable Area End
}
