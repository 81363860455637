Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "NavigationMenu";
exports.labelBodyText = "NavigationMenu Body";

exports.btnExampleTitle = "CLICK ME";

exports.openDrawerText = "Open Drawer";
exports.userName = "Jordan Ramsay";
exports.userDesignation = "Miami Herica";
exports.logout = "Logout";

exports.jsonApiContentType = "application/json";
exports.getMenuItemsEndPoint = "navmenu/navigation_menu";
exports.getApiMethodType = "GET";

exports.dashboardNav = "Dashboard";
exports.myAccountNav = "My Account";
exports.workOrdersNav = "Work Orders";
exports.preferredVendorNav = "Preferred Vendors";
exports.remindersNav = "Reminders";
exports.mypropertiesNav = "My Properties";
exports.financialNav = "Financial";
exports.reportNav = "Delinquency Report";
exports.chatNav = "Chat with Keasy";
exports.settingNav = "Settings";
exports.dashboardlabel = "Dashboard";
exports.myAccountlabel = "AccountGroups";
exports.workOrderslabel = "Appointments";
exports.preferredVendorlabel = "TaskList";
exports.reminderslabel = "AutomatedPrioritization";
exports.mypropertieslabel = "PortfolioManagement";
exports.financiallabel = "financial";
exports.reportlabel = "report";
exports.chatlabel = "Chat";
exports.settinglabel = "Settings2";

// Customizable Area End